import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  formContainer: {
    margin:0,
    width: '100%',
    marginBottom:20
  },
  dense: {
    marginTop: 19,
  },
  txtContentTable:{
    fontWeight:200,
    fontSize:15,
  },
  txtAction:{
    textTransform:'none',
    color:'#bf272b',
    fontWeight:200,
    fontSize:15
  },
  searchField: {
    // padding: '0 5px',
    marginRight:20,
    background: 'white',
    width: '100%',
    fontWeight:400,
    borderRadius: 5,
    borderColor: 'white',
    boxShadow: '2.5px 2.5px 2.5px 1.5px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
        borderRadius: 10,
    }
},
textSearch: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#6B6B6B',
    [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        padding:"13px 10px"
    }
},
  btnAction:{
    marginRight:10,
    border:'2px solid #bf272b'
  },
}));
