import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  formContainer: {
    margin:0,
    width: '100%',
    marginBottom:20,
  },
  dense: {
    marginTop: 19,
  },
  txtContentTable:{
    fontWeight:300,
    fontSize:15,
    textAlign:'center'
  },
  txtContentTable1:{
    fontWeight:300,
    fontSize:15,
    marginLeft:10,
    marginRight:10,
  },
  txtAction:{
    textTransform:'none',
    color:'#bf272b',
    fontWeight:200,
    fontSize:15
  },
  btnAction:{
    width:'100%',
    border:'2px solid #bf272b',
    marginBottom:10
  },
  btnActionAdd:{
    width:120,
    marginRight:10,
    border:'2px solid #bf272b',
    marginBottom:10
  },
  input: {
    display: 'none',
  },
  editorToolbar: {
    marginTop:10,
    width: "-webkit-fill-available",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    border:'1px solid #a8a8a8',
  },
  containerEditor: {
    backgroundColor: "#ffffff",
  },
  btnUpload:{
    backgroundColor:'gray',
    textTransform:'none'
  },
  containerCard:{
    paddingLeft:10,
    paddingTop:20,
    paddingRight:10,
    paddingBottom:20
  },
  listUser:{
    backgroundColor:'#dbdbdb',
    padding:0,
    width:300,
  },
  chooseUserBtn:{
    width:'100%',
    marginTop:10,
    marginBottom:10,
    backgroundColor:'#ffffff',
    border:'1px solid #a2aab8'
  },

  chooseUserTxt:{
    textTransform:'none',
    fontSize:14
  },

  txtTitleCategory:{
    marginLeft:16,
    fontSize:18,
    fontWeight:300
  },

  btnAdd:{
    position:'absolute',
    right:40,
  },

  txtTitleTable:{
    fontSize:15,
    fontWeight:250,
    color:'#545a63',
  },

  loginLoader: {
    marginLeft: theme.spacing(1),
  },

  sizePicture:{
    [theme.breakpoints.down('sm')]: {
      height:150
    },
    [theme.breakpoints.up('md')]: {
      height:150
    },
    width:'100%'
  },

  contentPhotos:{
    height:230,
    backgroundColor:'#ffffff',
  }
}));
