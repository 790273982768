import React, { useState,useEffect } from "react";
import {
  Grid, 
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Hidden,
  CircularProgress,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";

import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';

// styles
import useStyles from "./styles";

import { NavLink } from "react-router-dom"
import axios from "axios";

import PageTitle from "../Components/PageTitle/PageTitle";
import checklist from "../../Assets/Images/checklist.png";
import none from "../../Assets/Images/none.png";

import Dialog from "../Components/Dialog/Dialog";
import { InputAdornment } from "@material-ui/core";
import { Player, PosterImage, BigPlayButton, LoadingSpinner } from 'video-react';
import StaticVar from "../../Config/StaticVar"
import moment from 'moment'
import "../../../node_modules/video-react/dist/video-react.css"
//import "   node_modules/video-react/dist/video-react.css";
export default function Content(props) {
  const url = StaticVar.URL_API//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const [data, setData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  var [idNews, setIdNews] = useState("");
  var [titleNews, setTitleNews] = useState("");
  var [loading, setLoading]= useState(false)
  var [users, setusers]= useState([])
  const [filterstartdate, setfilterstartdate] = useState(moment().format("YYYY-MM-DD"))
  const [filterenddate, setfilterenddate] = useState(moment().format("YYYY-MM-DD"))
  const [filtercontributor, setfiltercontributor] = useState("0")

  function getData() {
    setLoading(true)    
    var urlnews = url+"/private/videonews?startdate="+filterstartdate+"&enddate="+filterenddate    
    if(localStorage.user_access !== "admin"){
      urlnews = url+"/private/videonews?startdate="+filterstartdate+"&enddate="+filterenddate+"&creator_id="+localStorage.id
      setfiltercontributor(localStorage.id)
    }
    else{
      if(filtercontributor!== "0"){
        urlnews = url+"/private/videonews?startdate="+filterstartdate+"&enddate="+filterenddate+"&creator_id="+filtercontributor
      }
    }
    axios.get(urlnews,{headers}).then(res=>{
      let response = res.data
      setLoading(false)
      console.log('load data news -> user', localStorage.getItem('user_access'))
      var user_access = localStorage.getItem('user_access');
      var user_id = localStorage.getItem('id');
      var data_response = response;
      console.log('data',response)
      var data_table = [];
     
      
      if(user_access === "kontributor"){
        data_response.forEach(element => {
          if(element.created.create_by === user_id){
            data_table.push(element);
          }
        });
        console.log('dataTable',JSON.stringify(data_table))
        setRows(data_table);
        setData(data_table);
        
      }
      else if(user_access === "editor"){
        axios.get(url+"/private/news_categories/editor/"+user_id,{headers}).
        then((res)=>{
          console.log('category', res.data);
          var dataCategory = res.data;
          data_response.forEach(element =>{
            dataCategory.forEach(elementCategory =>{
              if(element.category === elementCategory._id){
                data_table.push(element);
              }
            })
          })
          console.log('dataTable',JSON.stringify(data_table))
          setRows(data_table);
          setData(data_table);
          
        })
      }
      else{
        data_table = data_response;
        console.log('dataTable',JSON.stringify(data_table))
        setRows(data_table);
        setData(data_table);
        
      }
    }).catch(err=>console.log(err))
    
    
  }

  useEffect(() => {
    getData();
    axios.get(url+'/private/users',{headers}).then(res=>setusers(res.data))
    // return () => {
    //   getData();
    // }
  }, []);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  var classes = useStyles();

  var titleTable = [
    {name:'No'},{name:'Foto'},{name:'Title'},{name:'Kategori'},{name:'Redaksi'},{name:'Status'},{name:'Action'}
  ]

  const handleClickOpen = () => {
    props.history.push('/app/videonews/content/form-content')
  };

  // const check = (item)=>{
  //   if(item != ''){
  //     return(
  //       <img src={checklist} style={{width:20,height:20}}/>
  //     )
  //   }
  //   else{
  //     return(
  //       <img src={none} style={{width:20,height:20}}/>
  //     )
  //   }
  // }

  function deleteData(id) {
    axios.delete(url+"/private/videonews/"+id,{headers}).then(()=> {
      setOpenModalDelete(false);
      getData();
    })
  }

  function handleClickOpenDelete(id,title) {
    setIdNews(id);
    setTitleNews(title);
    setOpenModalDelete(true);
  };

   /** Table Pagination */
   const [order, setOrder] = React.useState('asc');
   const [orderBy, setOrderBy] = React.useState('calories');
   const [selected, setSelected] = React.useState([]);;
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);
   const [rows, setRows] = useState([]);
   
   const [search, setSearch] = useState("")
   const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e)=>{
      return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
    })
    setRows(result)    
    setSearch(searchQuery)
  }
 
   function desc(a, b, orderBy) {
     if (b[orderBy] < a[orderBy]) {
       return -1;
     }
     if (b[orderBy] > a[orderBy]) {
       return 1;
     }
     return 0;
   }
   
   function stableSort(array, cmp) {
     const stabilizedThis = array.map((el, index) => [el, index]);
     stabilizedThis.sort((a, b) => {
       const order = cmp(a[0], b[0]);
       if (order !== 0) return order;
       return a[1] - b[1];
     });
     return stabilizedThis.map(el => el[0]);
   }
   
   function getSorting(order, orderBy) {
     return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
   }
 
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
 
   const handleChangeRowsPerPage = event => {
     setRowsPerPage(+event.target.value);
     setPage(0);
   };


  return (
    <>
      <PageTitle title="Konten Video Berita" button="Tambah Konten" click={handleClickOpen}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                  <InputAdornment position='start'>
                      <SearchIcon />
                  </InputAdornment>
              ),
              classes : {input : classes.textSearch}
            }}
          />
        }
      />
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          Cari Berdasarkan : 
          {/* <Select style={{marginRight:5}} value={filterdate} onChange={(e)=>setfilterdate(e.target.value)}>
            <MenuItem>Pilih Tanggal</MenuItem>
          </Select> */}
          Dari Tgl <TextField type="Date" value={filterstartdate} onChange={e=>setfilterstartdate(e.target.value)}/> sampai <TextField type="Date" value={filterenddate} onChange={e=>setfilterenddate(e.target.value)} style={{marginRight:5}}/>
          <Select style={{marginRight:5}} disable={localStorage.user_access === "admin" ? 'false': 'true'} value={filtercontributor} onChange={(e)=>setfiltercontributor(e.target.value)}>
            <MenuItem value="0">Pilih Kontributor</MenuItem>
            {
              users.map(item=>{
                return(
                  <MenuItem key={item._id} value={item._id}>{item.email}</MenuItem>
                )
              })
            }
          </Select>
          <Button variant="contained" color="primary" onClick={()=>{
            getData()
          }}>Cari</Button>
        </Box>
        </Grid>
        <Grid item xs={12}>
          
          <Paper>
            {/* <Hidden smDown> */}
            {
              loading ? 
              <center style={{padding:50}}>
                <CircularProgress />
                <Typography>Sedang memuat data...</Typography>
              </center>:
              <>
                <Table>
                  <TableHead>
                  <TableRow>                   
                        <TableCell style={{textAlign:'center',width:'3%'}}>No</TableCell>
                        <TableCell style={{textAlign:'center',width:'10%'}}>Foto</TableCell>
                        <TableCell style={{textAlign:'center',width:'20%'}}>Video</TableCell>
                        <TableCell style={{textAlign:'left',width:'40%'}}>Judul</TableCell>
                        <TableCell style={{textAlign:'left',width:'10%'}}>Redaksi</TableCell>
                        <TableCell style={{textAlign:'center',width:'10%'}}>Status</TableCell>
                        <TableCell style={{textAlign:'center',width:'10%'}}>Aksi</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      rows.length > 0 ?
                      stableSort(rows, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => {
                        let creator_id = item.creator.length>0? item.creator[0]._id:""
                        return(
                            <TableRow key={index}>
                              <TableCell style={{textAlign:'center',width:'3%'}}><Typography className={classes.txtContentTable}>{(page * rowsPerPage)+(index + 1)}</Typography></TableCell>
                              <TableCell style={{textAlign:'center',width:'15%'}}>
                                <img src={url+"/repo/"+item.picture} style={{width:160}}/>
                                <br/>
                                <small style={{fontSize:8}}><i>{item.caption}</i></small>
                              </TableCell>
                              <TableCell style={{textAlign:'center',width:'15%'}}>
                                {item.link == "" ? (
                                  null
                                ) : (
                                  <Player
                                      poster={url+"/repo/"+item.picture ? url+"/repo/"+item.picture : url+"/repo/"+item.picture}
                                      fluid={true}
                                      playsInline
                                      width={"100px"}
                                      src={item.link ? item.link : ""}
                                  >
                                      <BigPlayButton position="center" />
                                      <LoadingSpinner />
                                  </Player>
                                  
                                )}
                              </TableCell>
                              <TableCell style={{width:'40%'}}>
                                <Typography style={{fontSize:18}}>{item.news_category && item.news_category.length > 0 ? item.news_category[0].name : null}</Typography>
                                <Typography style={{fontSize:16}}>Judul : {item.title}</Typography>
                                <Typography style={{fontSize:16}}>Title : {item.titleEN}</Typography>
                                <Typography style={{fontSize:12}}>Dibuat Tgl : {moment(item.created.create_date).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                              </TableCell>
                              
                              <TableCell style={{width:'10%'}}>
                                <Typography style={{fontSize:13}}>Kontributor : {item.creator.length>0? item.creator[0].email: ""}</Typography>
                                {/* {
                                  item.editor.length > 0 ? 
                                  <Typography style={{fontSize:13}}>Editor : {item.editor.length>0? item.editor[0].name: ""} </Typography >
                                  : null
                                }
                                {
                                  item.publisher.length > 0 ? 
                                  <Typography style={{fontSize:13}}>Publisher : {item.publisher.length>0? item.publisher[0].email: ""} </Typography >
                                  : null
                                } */}
                              </TableCell>
                              <TableCell style={{textAlign:'center',width:'10%'}}><Typography className={classes.txtContentTable}>{item.news_status}</Typography></TableCell>
                              <TableCell style={{textAlign:'center',width:'10%'}}>
                                {/* <Button className={classes.btnAction} component={NavLink} to={{pathname:"/app/news/content/view-content/"+item._id}}><Typography className={classes.txtAction}>View</Typography></Button> */}
                                <Button className={classes.btnAction} component={NavLink} to={"/app/videonews/content/editor-content/"+item._id}><Typography className={classes.txtAction}>Edit</Typography></Button>
                                {
                                  localStorage.getItem('user_access') === "editor" || localStorage.getItem('user_access') === "kontributor" ? null :   
                                  localStorage.getItem('user_access') === "redaktor" && localStorage.getItem("id") !== creator_id ? null :                           
                                  <Button className={classes.btnAction} onClick={()=>handleClickOpenDelete(item._id,item.title)}><Typography className={classes.txtAction}>Hapus</Typography></Button>
                                }
                              </TableCell>
                            </TableRow>
                          )
                      }):null
                    }
                  </TableBody>
                </Table>
                
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            }
            
            
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>Berita dengan Judul : {titleNews}</Typography>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idNews)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />
    </>
  );
}
