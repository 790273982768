import React, { useState , useEffect,useRef } from "react";
import {
  Grid, 
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TablePagination,
  List,
  ListItem,
  TextField,
  ListItemIcon,
  Checkbox,
  Card,
} from "@material-ui/core";

import InboxIcon from '@material-ui/icons/Inbox';

import _ from "lodash";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// styles
import useStyles from "./styles";
import axios from "axios";

import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";

import NoImg from "../../Assets/Images/no-image.png";
import StaticVar from "../../Config/StaticVar";
import Api from "../../Services/Api";

export default function VideosCategories(props) {
  var classes = useStyles();

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  var [idVideosCategories, setIdVideosCategories] = useState("");
  var [nameVideosCategories, setNameVideosCategories] = useState("");

  const [usersEditor, setUsersEditor] = useState([]);
  const [usersContributor, setUsersContributor] = useState([]);
  const [usersRedactor, setUsersRedactor] = useState([]);

  const [openModalChooseUserContributor, setOpenModalChooseUserContributor] = useState(false);
  const [openModalChooseUserEditor, setOpenModalChooseUserEditor] = useState(false);
  const [openModalChooseUserRedactor, setOpenModalChooseUserRedactor] = useState(false);
  
  var [imgPreview, setImgPreview ] = useState(NoImg);
  // var [imgEditPreview, setImgEditPreview ] = useState();
  var [img, setImg ] = useState("");
  var [isImgValid, setIsImgValid ] = useState("");
  var [imgErrorMsg, setImgErrorMsg ] = useState("");

  const [data, setData] = useState([])
  const [dataOrganization, setDataOrganization] = useState([])

  var [index, setIndex] = useState(0)

  const handleClickOpenAdd = () => {
    setOpenModalAdd(true);
  };

  const getDataOrganization = () => { 
    Api.getOrganization()
      .then((res)=>{
        const data = res.data
        const dataSection = data.filter((val)=> val.level === "Seksi")
        setDataOrganization(dataSection)
      })
   }

  function handleClickOpenEdit(id,name,picture){
    setIdVideosCategories(id);
    setNameVideosCategories(name);
    setImgPreview(url+"/repo/"+picture);
    setOpenModalEdit(true);
  };

  function handleClickOpenDelete(id,name) {
    setIdVideosCategories(id);
    setNameVideosCategories(name);
    setOpenModalDelete(true);
  };

  function handleClickOpenChooseUser(x, _id){
    if(x === "kontributor"){
      setIdVideosCategories(_id);
      setOpenModalChooseUserContributor(true);
    }
    else if(x === "editor"){
      setIdVideosCategories(_id);
      setOpenModalChooseUserEditor(true);
    }
    else{
      setIdVideosCategories(_id);
      setOpenModalChooseUserRedactor(true);
    }
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
    setOpenModalChooseUserContributor(false);
    setOpenModalChooseUserEditor(false);
    setOpenModalChooseUserRedactor(false);
  };

  const url = StaticVar.URL_API;// "http://localhost:301"//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const getData = async () => {
    let res = await axios.get(url+"/private/videos_categories",{headers});
    let response = await res.data
    setData(response);
    setRows(response);
  }

  const getDataUsers = async () => {
    let res = await axios.get(url+"/private/users",{headers});
    let response = await res.data

    var dataUserEditor = _.filter(response, function(item){
      return item != null && item.user_access == "editor";
    });
    setUsersEditor(dataUserEditor);

    var dataUserContributor = _.filter(response, function(item){
      return item != null && item.user_access == "kontributor";
    });
    dataUserContributor.forEach(item=>{
      item["check_status"] = false
    })
    console.log('dataUserContributor',dataUserContributor)
    setUsersContributor(dataUserContributor);

    var dataUserRedaktor = _.filter(response, function(item){
      return item != null && item.user_access == "redaktor";
    });
    setUsersRedactor(dataUserRedaktor);
  }

  function handleInput(e){
    setNameVideosCategories([e.target.name]= e.target.value)
    console.log('data post : ',nameVideosCategories);
  }

  
  let sendData = new FormData();
  sendData.append('name', nameVideosCategories);
  sendData.append('thumbnail', img);

  function postData(){
    axios.post(url+"/private/videos_categories/create",sendData,{headers}).then(res=> {
      setOpenModalAdd(false);
      getData();
    })
  }

  //for reload page javascript  window.location.reload();

  function editData(id){
    axios.put(url+"/private/videos_categories/"+id,sendData,{headers}).then(()=> {
      setOpenModalEdit(false);
      getData();
      //window.location.reload();
    })
  }

  function deleteData(id) {
    axios.delete(url+"/private/videos_categories/"+id,{headers}).then(res=> {
      setOpenModalDelete(false);
      getData();
    })
  }

  useEffect(() => {
    getDataOrganization()
    getData();
    getDataUsers();
    return () => {
      getData();
      getDataUsers();
    }
  }, []);

  const imgRef = useRef(null);

  function handleInputFile(){
      // menghubungkan ke button input
      imgRef.current.click()
  }

  function handleImage(e){
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true)
      setImgErrorMsg("Format File tidak sesuai")
      return;
    }

    reader.onloadend = () => {
      setIsImgValid(false);
      setImgErrorMsg("");
      setImg(file);
      setImgPreview(reader.result)
    };

    reader.readAsDataURL(file);
  }

  /* delete user contributor editor and redactor*/
  async function deleteUser(x,id){
    let res = await axios.get(url+"/private/videos_categories",{headers});
    let response = await res.data
    response.forEach((item)=>{
      if(x === "contributor"){
        item.contributor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/videos_categories/"+item._id+"/contributor/"+id,{headers}).then(() => {
              getData();
            });
          }
        })
      }
      else if(x === "editor"){
        item.editor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/videos_categories/"+item._id+"/editor/"+id,{headers}).then(() => {
              getData();
            });
          }
        })
      }
      else{
        item.redactor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/videos_categories/"+item._id+"/redactor/"+id,{headers}).then(() => {
              getData();
            });
          }
        })
      }
    })
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function changedatarow(newdata){
    var datarows = rows;
    datarows.forEach((element,index)=>{
      if(newdata._id === element._id){
        datarows[index] = newdata;
      }
    })
  }

  return (
    <>
      <PageTitle title="Kategori Video" button="Tambah Kategori" click={handleClickOpenAdd} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
              <TableRow>
                <TableCell style={{textAlign:'center',width:'3%'}}>No</TableCell>
                {/* <TableCell style={{textAlign:'center',width:'12%'}}>Thumbnail</TableCell> */}
                <TableCell style={{textAlign:'center',width:'10%'}}>Seksi</TableCell>
                <TableCell style={{textAlign:'center',width:'65%'}}>Pilih User</TableCell>
                <TableCell style={{textAlign:'center',width:'10%'}}>Aksi</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {
                  stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell style={{width:'3%'}}><Typography className={classes.txtContentTable}>{(page*rowsPerPage)+(index+1)}</Typography></TableCell>
                    {/* <TableCell style={{width:'12%'}}><img src={url+"/repo/"+item.thumbnail} style={{width:80,height:75}}/></TableCell> */}
                    <TableCell style={{width:'10%'}}><Typography style={{fontWeight:300,fontSize:15}}>{item.name}</Typography></TableCell>
                    <TableCell style={{width:'65%'}}>
                    <Grid container spacing={3}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                              Kontributor
                              <Button className={classes.chooseUserBtn} onClick={()=>handleClickOpenChooseUser("kontributor", item._id)}><Typography className={classes.chooseUserTxt}>Pilih Kontributor</Typography></Button> 
                              {
                                item.contributor.map((item, index1)=>(
                                  <List key={index1} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem button>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{item.name}</Typography>
                                      <Button style={{minWidth:0}} onClick={()=>deleteUser("contributor",item._id)}><HighlightOffIcon style={{color:'red'}}/></Button>
                                    </ListItem>
                                  </List>
                                ))
                              }   
                            </Card>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                              Editor
                              <Button className={classes.chooseUserBtn} onClick={()=>handleClickOpenChooseUser("editor",item._id)}><Typography className={classes.chooseUserTxt}>Pilih Editor</Typography></Button>
                              {
                                item.editor.map((item, index2)=>(
                                  <List key={index2} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem button>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{item.name}</Typography>
                                      <Button style={{minWidth:0}} onClick={()=>deleteUser("editor",item._id)}><HighlightOffIcon style={{color:'red'}}/></Button>
                                    </ListItem>
                                  </List>
                                ))
                              }   
                            </Card>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                            Publisher
                              <Button className={classes.chooseUserBtn} onClick={()=>handleClickOpenChooseUser("redaktor",item._id)}><Typography className={classes.chooseUserTxt}>Pilih Redaktor</Typography></Button>
                              {
                                item.redactor.map((item, index3)=>(
                                  <List key={index3} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem button>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{item.name}</Typography>
                                      <Button style={{minWidth:0}} onClick={()=>deleteUser("redactor",item._id)}><HighlightOffIcon style={{color:'red'}}/></Button>
                                    </ListItem>
                                  </List>
                                ))
                              }    
                            </Card>
                          </Grid>
                          </Grid>
                        </TableCell>
                    <TableCell style={{width:'10%'}}>
                      <Button className={classes.btnAction} onClick={() => handleClickOpenEdit(item._id,item.name,item.thumbnail)}><Typography className={classes.txtAction}>Edit</Typography></Button>
                      <Button className={classes.btnAction} onClick={() => handleClickOpenDelete(item._id,item.name)}><Typography className={classes.txtAction}>Hapus</Typography></Button>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

{/* <==========================================================================================================================================> */}
      
      {/* modal add */}
      <Dialog
        open={openModalAdd}
        close={handleClose}
        valueCancel={"Batalkan"}
        title={"Form Tambah Kategori Video"}
        content={
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
            label="Kategori"
            className={classes.formContainer}
            margin="normal"
            value={nameVideosCategories}
            onChange={e => setNameVideosCategories(e.target.value)}
            name="name"
            />
            {/* <InputLabel htmlFor="id-section">Seksi Bidang</InputLabel>
              <Select
                className={classes.formContainer}
                value={nameVideosCategories}
                onChange={event => setNameVideosCategories(event.target.value)}
                name="section"
              >
                {dataOrganization?.map((item, index) => (
                  <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                ))}
              </Select> */}
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}> 
          Thumbnail : 
          <input
            accept="image/*"
            className={classes.input}
            ref={imgRef}
            multiple
            type="file"
            onChange={e =>handleImage(e)}
          />
            <Button className={classes.btnUpload} onClick={handleInputFile}>
              <Typography style={{color:'#ffffff'}}>Upload</Typography>
            </Button>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{marginLeft:20}}
            />
            {img.name}
          </Grid> */}
          </Grid>
        }
        cancel={handleClose}
        confirm={() => postData()}
        valueConfirm={"Tambah"}
      />

      {/* modal edit */}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        valueCancel={"Batalkan"}
        title={"Form Edit Kategori Video"}
        content={
          <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
            label="Kategori"
            className={classes.formContainer}
            margin="normal"
            value={nameVideosCategories}
            onChange={e => setNameVideosCategories(e.target.value)}
            name="name"
            />
            {/* <InputLabel htmlFor="id-section">Seksi Bidang</InputLabel>
              <Select
                className={classes.formContainer}
                value={nameVideosCategories}
                onChange={event => setNameVideosCategories(event.target.value)}
                name="section"
              >
                {dataOrganization?.map((item, index) => (
                  <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                ))}
              </Select> */}
          </Grid>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}> 
            Thumbnail : 
              <input
                accept="image/*"
                className={classes.input}
                ref={imgRef}
                multiple
                type="file"
                onChange={e =>handleImage(e)}
              />
              <Button className={classes.btnUpload} onClick={handleInputFile}>
                <Typography style={{color:'#ffffff'}}>Upload</Typography>
              </Button>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{marginLeft:20}}
            />
            {img.name}
            </Grid> */}
           </Grid>
        }
        confirm={()=>editData(idVideosCategories)}
        valueConfirm="Edit"
        cancel={handleClose}
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        valueCancel={"Batalkan"}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>{nameVideosCategories}</Typography>
          </Grid>
          </Grid>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idVideosCategories)}
        valueConfirm={"Hapus"}
      />

      {/* ======> Pilih User <======*/}

      {/* modal choose user contributor */}
      <Dialog
        open={openModalChooseUserContributor}
        close={handleClose}
        title={"Form Pilih Kontributor"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            usersContributor.map(item=>(
              <List key={item} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.name}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersContributor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                        }
                      });
                      setUsersContributor(usersContributor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
            ))
          }
          
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersContributor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          console.log(JSON.stringify(datastack));
          axios.put(url+'/private/videos_categories/contributor/'+idVideosCategories, {
            contributor : datastack
          }, {headers}).then(async(res)=>{    
            await changedatarow(res.data);
            setOpenModalChooseUserContributor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

      {/* modal choose user editor */}
      <Dialog
        open={openModalChooseUserEditor}
        close={handleClose}
        title={"Form Pilih Editor"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            usersEditor.map(item=>(
              <List component="nav" aria-label="main mailbox folders" key={item._id} className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.name}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersEditor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                        }
                      });
                      setUsersEditor(usersEditor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
            ))
          }
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersEditor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          axios.put(url+'/private/videos_categories/editor/'+idVideosCategories, {
            editor : datastack
          }, {headers}).then(async(res)=>{    
            await changedatarow(res.data);
            setOpenModalChooseUserEditor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

{/* modal choose user redactor*/}

      <Dialog
        open={openModalChooseUserRedactor}
        close={handleClose}
        title={"Form Pilih Redaktor"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
        {
          usersRedactor.map(item=>(
            <List key={item} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.name}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersRedactor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                        }
                      });
                      setUsersRedactor(usersRedactor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
          ))
        }
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersRedactor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          axios.put(url+'/private/videos_categories/redactor/'+idVideosCategories, {
            redactor : datastack
          }, {headers}).then(async(res)=>{    
            await changedatarow(res.data);
            setOpenModalChooseUserRedactor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />
    </>
  );
}
