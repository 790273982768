import React, { useEffect, useState } from 'react'
import axios from 'axios'

import PageTitle from '../Components/PageTitle/PageTitle'
import DialogModal from '../Components/Dialog/Dialog'
import StaticVar from '../../Config/StaticVar'
import { 
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core'

const EditorSurvey = (props) => {
  const url = StaticVar.URL_API
  const id = props.match.params._id

  let token = localStorage.getItem("token")
  const headers = {
    "Content-Type": "application/json", 
    "x-access-token": token,
  }

  const [question, setQuestion] = useState("")
  const [listAnswer, setListAnswer] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [messageError, setMessageError] = useState("")

  const getData = async () => { 
    let res = await axios.get(url + "/private/survey_questions/" + id, { headers });
    let response = await res.data[0];
    console.log("Get Edit Data ", response)
    
    setQuestion(response.question)
    setListAnswer(response.listAnswer)
   }

   const handleInputs = () => { 
    let newInput = {name: '', value: ''}
    setListAnswer([...listAnswer, newInput])
   }

  const handleFormChange = (index, event) => { 
    let data = [...listAnswer]
    data[index][event.target.name] = event.target.value
    setListAnswer(data)
   }

  const removeInputs = (index) => { 
    let data = [...listAnswer]
    data.splice(index, 1)
    setListAnswer(data)
   }

  const handleSubmit = () => { 
    let sendData = {question: question, listAnswer: listAnswer}
    axios
      .put(`${url}/private/survey_questions/${id}`, sendData, { headers })
      .then(()=>{
        // console.log("History ", props.history);
        props.history.push('/app/survey/list')
      })
   }

  useEffect(()=>{
    getData()
  }, [])
  
  return (
    <>
    <PageTitle 
      title="Survey Editor"
    />
    <DialogModal 
      open={openAlert}
      close={() => setOpenAlert(false)}
      title={"Periksa Data isian Anda ??"}
      content={<Typography>{messageError}</Typography>}
      cancel={() => setOpenAlert(false)}
      confirm={() => setOpenAlert(false)}
      valueConfirm={"Oke"}
      valueCancel={"Tutup"}
    />
     <Box component='div'>
      <Grid container spacing={6}>
        <Grid item xs={12}>
        <TextField
          fullWidth
          id="questions"
          label="Pertanyaan"
          value={question}
          required
          multiline
          minRows={4}
          onChange={e => setQuestion(e.target.value)}
            // value={value}
            // onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
        {listAnswer.map((answer, index)=>{
            return(
              <Box key={index} component='div' sx={{ display: 'flex'}}>
                <Box width='80%' mr={2} mb={2}>
                  <TextField
                    fullWidth 
                    name='name'
                    placeholder='Pilihan jawaban'
                    onChange={event => handleFormChange(index, event)}
                    value={answer.name}
                    // sx={{ mb: 1, mr: 1 }}
                    // key={index}
                    />
                </Box>
                <Box width='80%' mr={2} mb={2}>
                  <TextField
                    fullWidth 
                    name='value'
                    placeholder='Nilai'
                    onChange={event => handleFormChange(index, event)}
                    value={answer.value}
                    // sx={{ mb: 1, mr: 1 }}
                    // key={index}
                    />
                </Box>
                {index == 0 ?
                  null 
                  :
                  <Box>
                    <Button variant='outlined' color='error' onClick={removeInputs}>Hapus</Button>
                  </Box>
                }
            </Box>
            )
          })}
        </Grid>
        <Grid item xs={6}>
          <Button variant='outlined' onClick={handleInputs}>Tambah Pilihan..</Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Button variant='contained' onClick={() => props.history.push('/app/survey/list')}>Batal</Button>
            </Grid>
            <Grid item xs={1}>
              <Button onClick={handleSubmit} variant='contained' color='primary'>Simpan</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </>
  )
}

export default EditorSurvey