import React from "react";
import axios from "axios";
import StaticVar from "../Config/StaticVar";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !! localStorage.getItem("token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,signExpired };

// ###########################################################

function loginUser(dispatch, email, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!email && !!password) {

    axios.post(StaticVar.URL_API+"/users/authenticate", {
        email: email,
        password: password
      })
      .then(function (response) {
        if(response.data.status === "success")
        {
          setTimeout(() => {
            console.log('disini');
            // const token = localStorage.getItem("token");
            // localStorage.setItem("token", token);
            // localStorage.setItem("email", email);
            // localStorage.setItem("password", password);      
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("user_access", response.data.data.user.user_access);
            localStorage.setItem("name", response.data.data.user.name);
            localStorage.setItem("id", response.data.data.user._id);
            dispatch({ type: "LOGIN_SUCCESS"});
            setError(null);
            setIsLoading(false);
            //history.push("/app/dashboard");
          }, 2000);
        } 
        else {
          console.log('error nya disini')
          dispatch({ type: "LOGIN_FAILURE" });
          setError(true);
          setIsLoading(false);
        }       
        
      })
      .catch(function (error) {
        console.log(error);
      });
  }    
}

function signOut(dispatch, history) {
  localStorage.removeItem("user_access");
  localStorage.removeItem("token");
  localStorage.removeItem("user_access");
  localStorage.removeItem("name");
  localStorage.removeItem("user");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  console.log('Sign Out');
  console.log('history',history);
  history.push("/login");
}

function signExpired(dispatch, history) {
  localStorage.removeItem("user_access");
  localStorage.removeItem("token");
  localStorage.removeItem("user_access");
  localStorage.removeItem("name");
  localStorage.removeItem("user");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  console.log('Expired');
  console.log('history',history);
  history.push("/login");
}
