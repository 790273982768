import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  FileCopyOutlined
} from "@material-ui/icons";
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoFile from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import Api from "../../Services/Api"

export default function EditorContent(props) {
  var classes = useStyles();
  const id = props.match.params._id;

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  let [data, setData] = useState([]);
  const [category, setCategory] = useState([])
  let [title, setTitle] = useState('');
  let [titleEN, setTitleEN] = useState('');
  let [name, setName] = useState('');
  let [nameEN, setNameEN] = useState('');
  let [file, setFile] = useState('');
  let [status, setStatus] = useState("");
  var [FilePreview, setFilePreview] = useState(NoFile);
  var [dataFile, setDataFile] = useState("");
  var [isFileValid, setIsFileValid] = useState("");
  var [FileErrorMsg, setFileErrorMsg] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [loading, setLoading] = useState(false);

  const getData = () => {
    if (id !== "0") {
      Api.getDocumentById(id).then(res => {
        console.log("response", res.data)
        if (res.data.length > 0) {
          let result = res.data[0]
          setTitle(result.title)
          setTitleEN(result.titleEN)
          setCategory(result.category)
          setName(result.name)
          setNameEN(result.nameEN)
          setFile(result.file)
        }
      }).catch((err) => {
        console.log("error", err)
      })
    }
  }

  const getDataCategory = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== 'admin') {
      var status = "contributor"

      if (user_access === "editor")
        status = "editor"

      if (user_access === "redaktor")
        status = "redactor"

      let res = await axios.get(url + "/private/document_categories/" + status + "/" + user_id, { headers },);
      let response = await res.data
      setData(response)
    } else {
      await axios.get(url + "/private/document_categories", { headers }).then(async (res) => {
        setData(res.data)
        console.log("Data Category ", res.data)
      })
    }
  }

  useEffect(() => {
    getData()
    getDataCategory()
  }, []);

  async function handleDoc(e) {
    let reader = new FileReader();
    console.log("Files is ", e.target.files)
    let file = e.target.files[0],
      pattern = /application\/pdf/

    if (!file.type.match(pattern)) {
      setIsFileValid(true)
      setFileErrorMsg("Format file tidak sesuai. Pastikan untuk upload file pdf")
      return
    }

    reader.onloadend = () => {
      setIsFileValid(false);
      setFileErrorMsg("");
      setDataFile(file);
      setFilePreview(reader.result);
    };

    reader.readAsDataURL(file);

    let sendData = new FormData();
    sendData.append("file", file);

    await setLoading(true)
    Api.postUploadDocument(sendData).then(res => {
      setFile(res.data)
      setLoading(false)
    }).catch(err => {
      console.log("err", err)
      setLoading(false)
    })

  }

  return (
    <>
      {/* <Backdrop className={classes.backdrop} open={loading} onClick={()=>setLoading(false)}>
    {
        loading ?
        <CircularProgress color="inherit" /> : null
    }
    </Backdrop> */}
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="secondary" />
      </Backdrop>
      <PageTitle title="Konten Editor" status={"Status : " + status} />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={5} style={{ marginBottom: 10 }}>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Nama Materi"
                value={name}
                name="name"
                onChange={event => setName(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="type-document">Jenis Materi</InputLabel>
                <Select
                  id="type-document"
                  label='Jenis Materi'
                  value={title}
                  name="title"
                  onChange={e => setTitle(e.target.value)}
                >
                  <MenuItem value="">Pilih...</MenuItem>
                  {
                    data.map((item, idx) => (
                      <MenuItem key={idx} value={item.title}>{item.title}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 15, marginBottom: 15 }}>
            <InputLabel htmlFor="contributor-helper">File Materi</InputLabel>
            <input
              className={classes.input}
              id="contained-button-file"
              multiple
              // ref={FileRef}
              type="file"
              onChange={e => handleDoc(e)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.btnUpload}
              // onClick={handleInputFile}
              >
                <Typography style={{ color: "#ffffff" }}>
                  File Upload
                </Typography>
              </Button>
            </label>
            <br />
            {isFileValid ? (
              <Typography variant="caption" color="error">{FileErrorMsg}</Typography>
            ) : null}
            {
              file ?
                <a href={StaticVar.URL_API + '/repo/' + file} target="_blank">
                  <Button
                    variant="outlined"
                    style={{ border: '2px solid #bf0d00' }}
                  >
                    <FileCopyOutlined style={{ color: '#bf0d00' }} />
                    <Typography style={{ color: "#bf0d00", textTransform: 'none' }}>
                      {file}
                    </Typography>
                  </Button></a> : null
            }
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ float: 'right' }}>
            <Button
              style={{ width: 120, border: "2px solid gray", color: "black", marginRight: 10 }}
              onClick={() => {
                props.history.push("/app/gallery/document");
              }}
            >
              Batal
            </Button>
            <Button
              style={{
                width: 150,
                border: '2px solid #6f9ae8',
                marginRight: 10,
              }}
              onClick={() => {
                const sendData = {
                  title,
                  // category,
                  titleEN,
                  name,
                  nameEN,
                  file,
                  created: {
                    create_by: localStorage.id,
                    create_date: new Date()
                  }, document_status: "Konten Baru"
                }
                const editData = {
                  title,
                  // category,
                  titleEN,
                  name,
                  nameEN,
                  file,
                  created: {
                    create_by: localStorage.id,
                    create_date: new Date()
                  }
                }
                console.log("Data send ", sendData)
                console.log("Data edit ", editData)
                if (title === "") {
                  setopenAlert(true);
                  setmessageError("Data Judul Materi wajib diisikan");
                } else if (file === "") {
                  setopenAlert(true);
                  setmessageError("Data File Materi wajib diisikan");
                }
                else if (id !== "0") {
                  Api.putDocument(id, editData).then(() => {
                    props.history.push('/app/gallery/document')
                  }).catch(err => console.log("error", err))
                }
                else {
                  Api.postDocument(sendData).then(() => {
                    props.history.push('/app/gallery/document')
                  }).catch(err => console.log("error", err))
                }
              }}
            >
              Simpan Konten
            </Button>
            {id === "0" ? null : localStorage.getItem("user_access") === "kontributor" ? null : (
              <Button
                style={{
                  width: 150,
                  border: '2px solid #78f056',
                  marginRight: 10,
                }}
                onClick={() => {
                  const sendData = {
                    title,
                    // category,
                    titleEN,
                    name,
                    nameEN,
                    file,
                    edited: {
                      edit_by: localStorage.id,
                      edit_date: new Date()
                    },
                    document_status: "Telah diedit"
                  }
                  if (title === "") {
                    setopenAlert(true);
                    setmessageError("Data Judul Materi wajib diisikan");
                  } else if (file === "") {
                    setopenAlert(true);
                    setmessageError("Data File Materi wajib diisikan");
                  }
                  else if (id !== "0") {
                    Api.putDocument(id, sendData).then(() => {
                      props.history.push('/app/gallery/document')
                    }).catch(err => console.log("error", err))
                  }
                  else {
                    Api.postDocument(sendData).then(() => {
                      props.history.push('/app/gallery/document')
                    }).catch(err => console.log("error", err))
                  }
                }}
              >
                Submit Editor
              </Button>
            )}
            {
              id === "0" ? null :
                localStorage.getItem("user_access") === "kontributor" ||
                  localStorage.getItem("user_access") === "editor" ? null :
                  localStorage.getItem("user_access") === "admin" || localStorage.getItem("user_access") === "redaktor" ? (
                    <Button
                      style={{
                        width: 150,
                        border: '2px solid #bf272b',
                        color: '#bf272b'
                      }}
                      onClick={() => {
                        const sendData = {
                          title,
                          // category,
                          titleEN,
                          name,
                          nameEN,
                          file,
                          approved: {
                            approve_by: localStorage.id,
                            approve_date: new Date()
                          }, document_status: "Publish"
                        }
                        if (title === "") {
                          setopenAlert(true);
                          setmessageError("Data Judul Materi wajib diisikan");
                        } else if (file === "") {
                          setopenAlert(true);
                          setmessageError("Data File Materi wajib diisikan");
                        }
                        else if (id !== "0") {
                          Api.putDocument(id, sendData).then(() => {
                            props.history.push('/app/gallery/document')
                          }).catch(err => console.log("error", err))
                        }
                        else {
                          Api.postDocument(sendData).then(() => {
                            props.history.push('/app/gallery/document')
                          }).catch(err => console.log("error", err))
                        }
                      }}
                    >
                      Publish
                    </Button>
                  ) : null}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
