import {
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Button,
  Typography,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Dialog from "../Components/Dialog/Dialog";
import React, { useEffect, useState } from "react";
import StaticVar from "../../Config/StaticVar";
import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "axios";
import { komponen, aktivitas, bulan, sumber_dana } from "./Data";
import { FileCopyOutlined } from "@material-ui/icons";
import useStyles from "./styles";

const AddFunds = (props) => {
  const classes = useStyles()

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [title, setTitle] = useState("");
  const [component, setComponent] = useState("");
  const [funds, setFunds] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [activity, setActivity] = useState("");
  const [month, setMonth] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDoc = (e) => {
    let reader = new FileReader();
    let doc = e.target.files[0],
      pattern = /application-*/;

    if (!doc.type.match(pattern)) {
      console.log("Wrong extension file..");
      return;
    }

    // console.log("Docu ", reader);
    reader.onloadend = () => {
      setFilePreview(doc.name);
      setFile(doc);
    };

    reader.readAsDataURL(doc);
    // console.log("Doc ", reader);
  };

  const onSubmit = async () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("component", component);
    formData.append("funds", funds);
    formData.append("activity", activity);
    formData.append("month", month);
    formData.append("file", file);
    formData.append("type", "funds");
    formData.append("create_by", localStorage.id);

    console.log("Data ", JSON.stringify(formData));

    if (title === "") {
      setMsgError("Judul tidak boleh kosong!");
      setOpenAlert(true);
    } else if (component === "") {
      setMsgError("Komponen tidak boleh kosong");
      setOpenAlert(true);
    } else if (activity === "") {
      setMsgError("Aktivitas tidak boleh kosong");
      setOpenAlert(true);
    } else if (funds === "") {
      setMsgError("Sumber Dana tidak boleh kosong");
      setOpenAlert(true);
    } else if (month === "") {
      setMsgError("Bulan tidak boleh kosong");
      setOpenAlert(true);
    } else if (file === null) {
      setMsgError("Harap upload dokumen");
      setOpenAlert(true);
    } else {
      setLoading(true);
      axios({
        method: "post",
        url: url + "/private/report/create",
        data: formData,
        headers: headers,
      })
        .then(() => {
          setLoading(false);
          props.history.push("/app/report/funds");
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // setMsgError(err);
          // setOpenAlert(true);
        });
      // await axios
      //   .post(url + "/private/report/create", formData, headers)
      //   .then(() => {
      //     setLoading(false);
      //     props.history.push("/app/report/activity");
      //   });
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        {loading ? <CircularProgress color="secondary" /> : null}
      </Backdrop>
      <PageTitle name="Laporan Keuangan" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            className={classes.formContainer}
            fullWidth
            margin="normal"
            label="Judul"
            value={title}
            name="title"
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="component">Komponen</InputLabel>
            <Select
              id="component"
              label="Komponen"
              name="component"
              value={component}
              onChange={(e) => setComponent(e.target.value)}
            >
              <MenuItem value="">Pilih Komponen..</MenuItem>
              {komponen.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="activity">Aktivitas</InputLabel>
            <Select
              id="activity"
              label="Aktivitas"
              name="activity"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
            >
              <MenuItem value="">Pilih Aktivitas..</MenuItem>
              {aktivitas.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="month">Bulan</InputLabel>
            <Select
              id="month"
              label="Bulan"
              name="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <MenuItem value="">Pilih Bulan..</MenuItem>
              {bulan.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="funds">Sumber Dana</InputLabel>
            <Select
              id="funds"
              label="Sumber Dana"
              name="funds"
              value={funds}
              onChange={(e) => setFunds(e.target.value)}
            >
              <MenuItem value="">Pilih Sumber Dana..</MenuItem>
              {sumber_dana.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="file_document">File Dokumen</InputLabel>
          <input
            className={classes.input}
            id="file_document"
            multiple
            // ref={imgRef}
            type="file"
            onChange={(e) => handleDoc(e)}
          />
          <label htmlFor="file_document">
            <Button
              variant="contained"
              component="span"
              className={classes.btnUpload}
            >
              Upload
            </Button>
          </label>
          <br />
          {file ? (
            <a
              href={StaticVar.URL_API + "/repo/" + filePreview}
              target="_blank"
            >
              <Button
                variant="outlined"
                style={{ border: "2px solid #bf0d00" }}
              >
                <FileCopyOutlined style={{ color: "#bf0d00" }} />
                <Typography style={{ color: "#bf0d00", textTransform: "none" }}>
                  {filePreview}
                </Typography>
              </Button>
            </a>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              style={{
                width: 120,
                border: "2px solid gray",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={() => {
                props.history.push("/app/report/activity");
              }}
            >
              Batal
            </Button>
            <Button
              style={{
                width: 120,
                border: "2px solid #bf272b",
                color: "#bf272b",
                marginBottom: 10,
              }}
              onClick={onSubmit}
            >
              Simpan
            </Button>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={openAlert}
        close={() => setOpenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{msgError}</Typography>}
        cancel={() => setOpenAlert(false)}
        confirm={() => setOpenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
    </>
  );
};

export default AddFunds;
