import React, { useState,useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import axios from "axios";
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
// styles
import useStyles from "./styles";

import { NavLink } from "react-router-dom";

import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";
import moment from "moment"
import { InputAdornment } from "@material-ui/core";
import StaticVar from "../../Config/StaticVar";

export default function Pages(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const [openModalDelete, setOpenModalDelete] = useState(false);
  var [idpage, setidpage] = useState("");
  var [name, setname] = useState("");
  var [data, setData] = useState("");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);

  async function getData() {
    let res = await axios.get(url+"/private/pages",{headers});
    let response = await res.data
    console.log('user_access', localStorage.getItem('user_access'))
    setRows(response);
    setData(response)
  }

  useEffect(() => {
    getData();
    return () => {
      getData();
    }
  }, []);

  const [open, setOpen] = React.useState(false);

  const [search, setSearch] = useState("")
   const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e)=>{
      return e.name.toString().toLowerCase().indexOf(searchQuery) !== -1;
    })
    setRows(result)    
    setSearch(searchQuery)
  }

  const handleClickOpen = () => {
    props.history.push('/app/pages/new_page')
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalDelete(false);
  };

  /** Table Pagination */

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function deleteData(id) {
    axios.delete(url+"/private/pages/"+id,{headers}).then(()=> {
      setOpenModalDelete(false);
      getData();
    })
  }

  function handleClickOpenDelete(id,title) {
    setidpage(id);
    setname(title);
    setOpenModalDelete(true);
  };


  return (
    <>
      <PageTitle
        title="Laman"
        button="Tambah Laman"
        click={handleClickOpen}

        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                  <InputAdornment position='start'>
                      <SearchIcon />
                  </InputAdornment>
              ),
              classes : {input : classes.textSearch}
            }}
          />
        }
      />

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>Laman dengan Judul : {name}</Typography>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idpage)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{textAlign:'center',width:'3%'}}>No</TableCell>
                  {/* <TableCell style={{textAlign:'center',width:'25%'}}>Foto</TableCell> */}
                  <TableCell style={{width:'25%'}}>Nama Laman</TableCell>
                  <TableCell style={{textAlign:'center',width:'15%'}}>Setting Layar</TableCell>
                  <TableCell style={{textAlign:'center',width:'15%'}}>Pembuat</TableCell>
                  <TableCell style={{textAlign:'center',width:'7%'}}>Status</TableCell>
                  <TableCell style={{textAlign:'center',width:'7%'}}>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                  stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return(
                      <TableRow key={index}>
                        <TableCell style={{textAlign:'center',width:'3%'}}>
                          <Typography className={classes.txtContentTable}>
                            {(page * rowsPerPage)+(index + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell style={{width:'25%'}}>
                          <Typography>
                            Judul : {item.name}
                          </Typography>
                          <Typography>
                            Title : {item.nameEN}
                          </Typography>
                        </TableCell>
                        <TableCell style={{  width: "20%" }}>
                        {item.parent !== "Tanpa Parent" ? (
                          <Typography className={classes.txtContentTable}>
                           Induk Layar :  {item.data_parent[0].name}
                          </Typography>
                        ) : (
                          <Typography className={classes.txtContentTable}>
                           Induk Layar : Tanpa Parent
                          </Typography>
                        )}

                        {item.fullwidth ? (
                          <Typography className={classes.txtContentTable}>
                           Setting Layar : Penuh
                          </Typography>
                        ) : (
                          <Typography className={classes.txtContentTable}>
                           Setting Layar : Default
                          </Typography>
                        )}
                      </TableCell>
                        <TableCell style={{textAlign:'center',width:'15%'}}>
                          <Typography className={classes.txtContentTable}>
                            Pembuat : {item.creator && Array.isArray(item.creator) && item.creator.length > 0 ? item.creator[0].name : ""}
                          </Typography>
                          <Typography className={classes.txtContentTable}>
                            Tanggal : {moment(item.created.create_date).format("DD-MM-YYYY HH:mm:ss")}
                          </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:'center',width:'7%'}}>
                          <Typography className={classes.txtContentTable}>
                            {item.page_status}
                          </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:'center',width:'7%'}}>
                          <Button
                            className={classes.btnAction}
                            component={NavLink}
                            to={"/app/pages/edit_page/"+ item._id}
                          >
                            <Typography className={classes.txtAction}>
                              Edit
                            </Typography>
                          </Button>
                          <Button className={classes.btnAction} onClick={()=>handleClickOpenDelete(item._id,item.name)}>
                            <Typography className={classes.txtAction}>
                              Hapus
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
