import React, { useState, useEffect, useRef } from "react"
import { 
  Box, 
  FormControl, 
  Grid, 
  OutlinedInput, 
  Typography,
  Stack,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";

import Api from "../../Services/Api";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoFile from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import { ToggleButton, ToggleButtonGroup, Pagination } from "@material-ui/lab";

const FormOrganization = (props) => {

  const [level, setLevel] = useState("")
  const [name, setName] = useState("")
  const [parent, setParent] = useState("")

  const [dataHead, setDataHead] = useState([])
  const [dataDepartement, setDataDepartement] = useState([])
  const [dataSection, setDataSection] = useState([])
  
  const [titleAlert, setTitleAlert] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [messageError, setMessageError] = useState("")

  const addOrganization = () => { 
    let data = {
      name: name,
      level: level,
      parent: parent,
    }

    if(name === ""){
      setOpenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!")
      setMessageError("Data nama organisasi wajib diisikan")
    } else if(level === ""){
      setOpenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!")
      setMessageError("Data level wajib diisikan")
    } else{
      Api.postOrganization(data)
        .then((res)=> {
          // console.log(res)
          props.history.push('/app/organization')

        })
        .catch((err) => console.log(err))
    }

   }
  
  const getData = async () => { 
    await Api.getOrganization()
      .then((res)=>{
        const data = res.data
        console.log("Data ",data)
        
        const head = data.filter((item)=> item.level === "Pimpinan")
        const departement = data.filter((item)=> item.level === "Bidang")
        setDataHead(head)
        setDataDepartement(departement)

      })
      .catch(err => console.log(err))
   }

   useEffect(()=>{
     getData()
   },[])

  return (
    <>
    <PageTitle title='Organisasi' />
    <Box component='form'>
      <Grid container spacing={2}>
        <Grid item xs={2} >Nama Organisasi</Grid>
        <Grid item xs={10 }>
          <FormControl size="small" fullWidth>
            <OutlinedInput 
             value={name}
             onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>Pilih Level</Grid>
        <Grid item xs={10}>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center'}}>
            <ToggleButtonGroup
              // colo r="secondary"
              value={level}
              exclusive
              onChange={(e) => setLevel(e.target.value)}
            >
              <ToggleButton color="secondary" value='Pimpinan'>Pimpinan</ToggleButton>
              <ToggleButton color="secondary" value='Bidang'>Bidang</ToggleButton>
              <ToggleButton value='Seksi'>Seksi</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        {level === "Bidang" ? (
          <>
          <Grid item xs={2}>
            <Box>Pilih Pimpinan</Box>
          </Grid>
          <Grid item xs={10}>
            <FormControl variant="outlined" size="small">
              <Select
                value={parent}
                onChange={(e)=> setParent(e.target.value)}
              >
                {dataHead?.map((item, index)=> (
                  <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          </>
        ) : level === 'Seksi' ? (
          <>
          <Grid item xs={2}>
            <Box>Pilih Bidang</Box>
          </Grid>
          <Grid item xs={10}>
            <FormControl variant="outlined" size="small">
              <Select
                value={parent}
                onChange={(e)=> setParent(e.target.value)}
              >
                {dataDepartement?.map((item, index)=> (
                  <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Button onClick={ () => props.history.push('/app/organization')} variant="outlined">Batalkan</Button>
            </Grid>
            <Grid item>
              <Button disableElevation color="primary" variant="contained" onClick={addOrganization}>Tambah</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

    <Dialog 
      open={openAlert}
      close={() => setOpenAlert(false)}
      title={"Periksa Data isian Anda ??"}
      content={<Typography>{messageError}</Typography>}
      cancel={() => setOpenAlert(false)}
      confirm={() => setOpenAlert(false)}
      valueConfirm={"Oke"}
      valueCancel={"Tutup"}
    />
    </>
  )
}

export default FormOrganization