import React, { useEffect, useState } from 'react'

import { 
  InputAdornment,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TextField,
  Typography,
  Grid,
  TableBody,
} from "@material-ui/core";

import { NavLink } from "react-router-dom";

import axios from 'axios'
import PageTitle from '../Components/PageTitle/PageTitle'
import Dialog from '../Components/Dialog/Dialog'
import StaticVar from '../../Config/StaticVar'
import moment from "moment"

import SearchIcon from '@material-ui/icons/Search'

import useStyles from "./styles"

const ListFaq = (props) => {
  const classes = useStyles()

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  }

  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [hashtag, setHashtag] = useState("")

  const [idFaq, setIdFaq] = useState("")

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("")

  const [data, setData] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState([])
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('question');


   const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e)=>{
      return e.name.toString().toLowerCase().indexOf(searchQuery) !== -1;
    })
    setRows(result)    
    setSearch(searchQuery)
  }

  const handleClickOpen = () => {
    props.history.push('/app/faq/form')
    //setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setOpenModalDelete(false);
  };

  const getData = async () => { 
    let res = await axios.get(url + '/private/faq', { headers})
    let response = await res.data
    console.log("Res ", response)
    setRows(response);
    setData(response);
   }


   /** Table Pagination */

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function deleteData(id) {
    axios.delete(url+"/private/faq/"+id,{headers}).then(()=> {
      setOpenModalDelete(false);
      getData();
    })
  }

  function handleClickOpenDelete(id,qst) {
    setIdFaq(id);
    setQuestion(qst);
    setOpenModalDelete(true);
  };


   useEffect(()=>{
    getData()
   }, [])

  return (
    <>
    <PageTitle
        title="FAQ"
        button="Tambah FAQ"
        click={handleClickOpen}

        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                  <InputAdornment position='start'>
                      <SearchIcon />
                  </InputAdornment>
              ),
              classes : {input : classes.textSearch}
            }}
          />
        }
      />

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>FAQ : {question}</Typography>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idFaq)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />

      {/* Table List */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{textAlign:'center',width:'3%'}}>No</TableCell>
                  <TableCell style={{width:'25%'}}>Pertanyaan</TableCell>
                  <TableCell style={{textAlign:'center',width:'7%'}}>Status</TableCell>
                  <TableCell style={{textAlign:'center',width:'7%'}}>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                  stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return(
                      <TableRow key={index}>
                        <TableCell style={{textAlign:'center',width:'3%'}}>
                          <Typography className={classes.txtContentTable}>
                            {(page * rowsPerPage)+(index + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell style={{width:'25%'}}>
                          <Typography>
                            {item.question}
                          </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:'center',width:'7%'}}>
                          <Typography className={classes.txtContentTable}>
                            {item.faq_status}
                          </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:'center',width:'7%'}}>
                          <Button
                            className={classes.btnAction}
                            component={NavLink}
                            to={"/app/faq/edit/"+ item._id}
                          >
                            <Typography className={classes.txtAction}>
                              Edit
                            </Typography>
                          </Button>
                          <Button className={classes.btnAction} onClick={()=>handleClickOpenDelete(item._id,item.name)}>
                            <Typography className={classes.txtAction}>
                              Hapus
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default ListFaq