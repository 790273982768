import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Box,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// styles
import useStyles from "./styles";



// logo
import KemdikbudLogo from './kemdikbud.png'
import KedairekaLogo from './kedaireka-transparent.png'

// context
import { useUserDispatch, loginUser } from "../../Context/UserContext";
import { Visibility, VisibilityOff, WidgetsSharp } from "@material-ui/icons";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    };

  const handleSubmit = async (e) => {
    e.preventDefault()
    loginUser(
      userDispatch,
      emailValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    )
  }

  return (
    <Grid container className={classes.container}>
      
      <div className={classes.formContainer}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4
          }}>
          <Box 
          component={'img'}
          src={KemdikbudLogo}
          className={classes.logotypeImage}
          />
          <Box component='div' sx={{ mx: 2, borderLeft: '1.8px solid rgba(0,0,0, 0.4)', height: 50}} orientation="vertical" />
          <Box 
          component={'img'}
          src={KedairekaLogo}
          className={classes.logotypeImage}
          />

        </Box>
        <div className={classes.header}>
          <Typography className={classes.greeting}>
            Login Admin Portal
          </Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
            <React.Fragment>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
              </div>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <TextField
                id="email"
                name="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                onChange={e => setEmailValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              {/* <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                name="password"
                fullWidth
              /> */}
               <FormControl fullWidth>
                <InputLabel htmlFor="id-password">Password</InputLabel>
                <Input 
                  id="id-password"
                  type={showPassword ? "text" : "password"}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  inputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      emailValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button> */}
              </div>
            </React.Fragment>
        </form>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
