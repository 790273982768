import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";

import { useDropzone } from "react-dropzone";

import VideoThumbnail from "react-video-thumbnail";

import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import StaticVar from "../../Config/StaticVar"

export default function GalleryVideos(props) {
  var classes = useStyles();

  const userAccess = localStorage.getItem('user_access')

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  var [idVideos, setIdVideos] = useState("");
  var [titleVideos, setTitleVideos] = useState("");
  var [categoryVideos, setCategoryVideos] = useState("");
  var [linkVideos, setLinkVideos] = useState("");

  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  var [openAlert, setopenAlert] = useState(false);
  var [loading, setLoading] = useState(false);
  var [messageError, setmessageError] = useState("");
  const [type, setType] = React.useState("");

  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  var [videoPreview, setVideoPreview] = useState(NoImg);
  var [video, setVideo] = useState("");
  var [isVideoValid, setIsVideoValid] = useState("");
  var [videoErrorMsg, setVideoErrorMsg] = useState("");
  var [caption, setcaption] = useState("");

  var [index, setIndex] = useState(0);

  const [files, setFiles] = useState([]);

  var titleTable = [
    { name: "No" },
    { name: "Thumbnail" },
    { name: "Video" },
    { name: "Judul" },
    { name: "Kategori" },
    { name: "Link" },
    { name: "Status" },
    { name: "Action" },
  ];

  useEffect(() => {
    getData();
    getDataCategoryVideos();
    return () => {
      getData();
      getDataCategoryVideos();
    };
  }, []);

  const url = StaticVar.URL_API;// "http://localhost:301"//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const getDataCategoryVideos = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/videos_categories/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/videos_categories", {
        headers,
      });
      let response = await res.data;
      setDataCategory(response);
    }
  };

  const getData = async () => {
    let res = await axios.get(url + "/private/Videos", { headers });
    let response = await res.data;

    console.log("user_access", localStorage.getItem("user_access"));
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    var data_response = response;
    // console.log("data", JSON.stringify(data_response));
    console.log('data', response)
    var data_table = [];

    if (user_access === "kontributor") {
      data_response.forEach(element => {
        if (element.created.create_by === user_id) {
          data_table.push(element);
        }
      });
      console.log("dataTable", JSON.stringify(data_table));
      setRows(data_table);
      setData(data_table);
    } else if (user_access === "editor") {
      axios
        .get(url + "/private/videos_categories/editor/" + user_id, { headers })
        .then(res => {
          console.log("category", res.data);
          var dataCategory = res.data;
          data_response.forEach(element => {
            dataCategory.forEach(elementCategory => {
              if (element.category === elementCategory._id) {
                data_table.push(element);
              }
            });
          });
          console.log("dataTable", JSON.stringify(data_table));
          setRows(data_table);
          setData(data_table);
        });
    } else {
      data_table = data_response;
      console.log("dataTable", JSON.stringify(data_table));
      setRows(data_table);
      setData(data_table);
    }
  };

  const handleClickOpenAdd = () => {
    props.history.push("/app/gallery/videos/add-videos/");
  };

  function handleClickOpenEdit(id, title, caption, category, thumbnail, link) {
    setImg("");
    setIdVideos(id);
    setTitleVideos(title);
    setcaption(caption)
    setCategoryVideos(category);
    setLinkVideos(link);
    setOpenModalEdit(true);
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };

  function handleImageThumbnail(e) {
    var byteString;
    if (e.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(e.split(",")[1]);
    else byteString = unescape(e.split(",")[1]);
    var mimeString = e
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var data = new Blob([ia], { type: mimeString });
    console.log("data", data);
    setImg(data);
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "video/*",
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );

      console.log("value : ", acceptedFiles);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const thumbs = files.map((file, index) => {
    if (file.title !== null && file.category !== null) {
      return (
        <div key={index}>
          <Grid container>
            <Grid item xs={7} style={thumb}>
              {/* <div style={thumbInner}> */}
              <video controls autoPlay width="100%" height="180">
                <source src={file.preview} type="video/mp4" />
                <source src={file.preview} type="video/webm" />
              </video>
              <Button
                onClick={() => setFiles([])}
                style={{
                  border: "1px solid #e0e0e0",
                  height: "20%",
                  minWidth: 40,
                  width: 40,
                }}
              >
                <HighlightOffIcon style={{ color: "red" }} />
              </Button>
              {/* </div> */}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <Typography>Thumbnail : </Typography>
            </Grid>
            <Grid item xs={7}>
              <div style={thumbnail}>
                <VideoThumbnail
                  videoUrl={file.preview}
                  thumbnailHandler={thumbnail =>
                    handleImageThumbnail(thumbnail)
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  });

  function postData() {
    if (titleVideos === "") {
      setopenAlert(true);
      setmessageError("Data Title wajib diisikan");
    } else if (categoryVideos === "") {
      setopenAlert(true);
      setmessageError("Data Kategori wajib diisikan");
    } else if (img === "") {
      setopenAlert(true);
      setmessageError("Data Image Foto wajib dimasukkan");
    } else {
      files.map(file => {
        const formData = new FormData();
        formData.append("title", titleVideos);
        formData.append("link", linkVideos);
        formData.append("thumbnail", img, titleVideos.concat("-thumbnail.png"));
        formData.append("video", file);
        formData.append("category", categoryVideos);
        formData.append("create_by", localStorage.id);
        formData.append("approved", []);
        formData.append("video_status", "Video Baru");

        axios
          .post(url + "/private/videos/create", formData, { headers })
          .then(() => {
            setOpenModalAdd(false);

            getData();
          });
      });
    }
  }

  //for reload page javascript  window.location.reload();

  function editData(id) {

    if (userAccess === 'kontributor') {
      dataEdit = {
        title: titleVideos,
        caption: caption,
        link: linkVideos,
        category: categoryVideos,
        create_by: localStorage.id,
        approved: [],
        video_status: "Video Baru",
      };
    } else {
      var dataEdit = {
        title: titleVideos,
        caption: caption,
        link: linkVideos,
        category: categoryVideos,
        create_by: localStorage.id,
        approved: [],
        video_status: "Telah diedit",
      };
    }

    axios
      .put(url + "/private/videos/" + id, dataEdit, { headers })
      .then(() => {
        setOpenModalEdit(false);
        getData();
      });
  }

  function editDataForPublish(id) {
    var dataPublish = {
      title: titleVideos,
      caption: caption,
      link: linkVideos,
      category: categoryVideos,
      create_by: localStorage.id,
      approved: [],
      video_status: "Publish",
    };
    axios
      .put(url + "/private/videos/" + id, dataPublish, { headers })
      .then(() => {
        setOpenModalEdit(false);
        getData();
      });
  }

  function deleteData(id) {
    axios.delete(url + "/private/videos/" + id, { headers }).then(res => {
      setOpenModalEdit(false);
      getData();
    });
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <PageTitle
        title="Galeri Video"
        button="Tambah Video"
        click={handleClickOpenAdd}
      />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "3%" }}>
                    <center>No</center>
                  </TableCell>
                  <TableCell style={{ width: "17%" }}>
                    <center>Thumbnail</center>
                  </TableCell>
                  <TableCell style={{ width: "17%" }}>
                    <center>Video</center>
                  </TableCell>
                  <TableCell style={{ width: "16%" }}>
                    <center>Judul</center>
                  </TableCell>
                  {/* <TableCell style={{ width: "15%" }}>
                    <center>Seksi</center>
                  </TableCell> */}
                  <TableCell style={{ width: "15%" }}>
                    <center>Link</center>
                  </TableCell>
                  <TableCell style={{ width: "7%" }}>
                    <center>Status</center>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <center>Aksi</center>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const links = item.link.split("=")
                    const idVideo = links[1]
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ width: "3%" }}>
                          <Typography className={classes.txtContentTable}>
                            {page * rowsPerPage + (index + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "17%" }}>
                          <img
                            src={url + "/repo/" + item.thumbnail}
                            style={{ marginTop: 30, width: 170, height: 130 }}
                          />
                        </TableCell>
                        <TableCell style={{ width: "17%" }}>
                          {item.link == "" ? (
                            <div style={{ width: 170, height: 130 }}>
                              <video controls width="100%" height="100%">
                                <source
                                  src={url + "/repo/" + item.video}
                                  type="video/mp4"
                                />
                                <source
                                  src={url + "/repo/" + item.video}
                                  type="video/webm"
                                />
                              </video>
                            </div>
                          ) : (

                            <iframe
                              width="170"
                              height="130"
                              src={`https://www.youtube.com/embed/${idVideo}`}
                            ></iframe>

                          )}
                        </TableCell>
                        <TableCell style={{ width: "16%" }}>
                          <Typography className={classes.txtContentTable}>
                            {item.title}
                          </Typography>
                        </TableCell>
                        {/* <TableCell style={{ width: "15%" }}>
                        <Typography className={classes.txtContentTable}>
                          {item.videos_category[0].name}
                        </Typography>
                      </TableCell> */}
                        <TableCell style={{ width: "15%" }}>
                          {item.link == "" ? (
                            <Typography className={classes.txtContentTable}>
                              Tidak ada Link
                            </Typography>
                          ) : (
                            <Typography className={classes.txtContentTable}>
                              {item.link}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          <Typography className={classes.txtContentTable}>
                            {item.video_status}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          <Button
                            className={classes.btnAction}
                            onClick={() =>
                              handleClickOpenEdit(
                                item._id,
                                item.title,
                                item.caption,
                                item.category,
                                item.thumbnail,
                                item.link,
                              )
                            }
                          >
                            <Typography className={classes.txtAction}>
                              Aksi
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* <==========================================================================================================================================> */}

      {/* modal add */}
      {/* <Dialog
        open={openModalAdd}
        close={handleClose}
        title={"Form Tambah Video"}
        valueCancel="Batalkan"
        content={
          
        }
        cancel={handleClose}
        confirm={() => postData()}
        valueConfirm={"Tambah"}
      /> */}

      {/* modal edit */}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Video"}
        content={
          <Grid container style={{ width: 500 }}>
            <Grid item xs={12}>
              <TextField
                label="Judul"
                className={classes.formContainer}
                margin="normal"
                value={titleVideos}
                onChange={event => setTitleVideos(event.target.value)}
                name="titleVideos"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Caption"
                className={classes.formContainer}
                margin="normal"
                value={caption}
                onChange={event => setcaption(event.target.value)}
                name="Caption"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl style={{ width: "100%", marginRight: 10 }}>
                <InputLabel htmlFor="outlined-age-simple">
                  Pilih Kategori Video
                </InputLabel>
                <Select
                  name="categoryVideos"
                  value={categoryVideos}
                  onChange={event => setCategoryVideos(event.target.value)}
                >
                  {/* <MenuItem value={'0'}>Semua</MenuItem> */}
                  {dataCategory.map(item => {
                    return <MenuItem key={item} value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            {linkVideos !== "" ? (
              <Grid item xs={12}>
                <TextField
                  label="Link"
                  className={classes.formContainer}
                  margin="normal"
                  value={linkVideos}
                  onChange={event => setLinkVideos(event.target.value)}
                  name="linkVideos"
                />
              </Grid>
            ) : null}
            <Grid item lg={12} style={{ height: 40 }}>
              <div style={{ position: "absolute", right: 15 }}>
                <Button
                  onClick={handleClose}
                  style={{ border: "2px solid #6f9ae8", marginRight: 10 }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Batalkan
                  </Typography>
                </Button>
                <Button
                  onClick={() => deleteData(idVideos)}
                  style={{ border: "2px solid #de1200", marginRight: 10 }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Hapus
                  </Typography>
                </Button>
                <Button
                  onClick={() => editData(idVideos)}
                  style={{ border: "2px solid #78f056", marginRight: 10 }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Simpan Konten
                  </Typography>
                </Button>
                {
                  localStorage.user_access === "admin" || localStorage.user_access === "redaktor" ?
                    <Button
                      onClick={() => editDataForPublish(idVideos)}
                      style={{ border: "2px solid #78f056" }}
                    >
                      <Typography style={{ textTransform: "none" }}>
                        Publish
                      </Typography>
                    </Button> : null
                }

              </div>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  flexDirection: "row",
  width: "100%",
};

const thumbnail = {
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  flexDirection: "row",
  width: "100%",
  height: 180,
};

// const thumbInner = {
//   display: 'flex',
//   minWidth: 0,
//   overflow: 'hidden'
// };
