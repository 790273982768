import React, { useState, useEffect, useRef} from 'react'
import imageCompression from 'browser-image-compression';
import { 
  Grid, 
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  TablePagination
} from '@material-ui/core'
import PageTitle from '../Components/PageTitle/PageTitle'
import Dialog from '../Components/Dialog/Dialog'

import StaticVar from '../../Config/StaticVar'
import NoImg from '../../Assets/Images/no-image.png'
import _ from 'lodash'
import axios from 'axios'

const Popup = () => {

  const [openDialogAdd, setOpenDialogAdd] = useState(false)
  const [openDialogEdit, setOpenDialogEdit] = useState(false)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)

  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [titleEN, setTitleEN] = useState("")
  const [link, setLink] = useState("")
  const [statusPopup, setStatusPopup] = useState("")

  const [data, setData] = useState([])
  const [imgPreview, setImgPreview] = useState([])
  const [img, setImg] = useState("")
  const [isImgValid, setIsImgValid] = useState("")
  const [imgErrorMsg, setImgErrorMsg] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [titleAlert, setTitleAlert] = useState(false)
  const [messageError, setMessageError] = useState(false)

  //Ref
  const imgRef = useRef(null)

  const url = StaticVar.URL_API;
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  }

  useEffect(() => {
    getData();
    return () => {
      getData();
    };
  }, []);

  const handleCloseDialog = () => { 
    setOpenDialogAdd(false)
    setOpenDialogEdit(false)
    setOpenDialogDelete(false)
   }

  const handleInputFile = () => { 
    imgRef.current.click()
   }

  const handleImage = async (e) => { 
    const reader = new FileReader()
    const file = e.target.files[0],
      pattern = /image-*/
    
    if (!file.type.match(pattern)) {
      setIsImgValid(true)
      setImgErrorMsg("Format file tidak sesuai..")
      return
    }

    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 3000,
      useWebWorker: true
    }

    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log('compressedFile size ',compressedFile); // smaller than maxSizeMB
   
      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result)
      };
  
      reader.readAsDataURL(compressedFile);

    } catch (error) {
      console.log(error);
    }
   }
  
  const handleClickOpenAdd = () => {
    setId("");
    setTitle("");
    setTitleEN("");
    setImg("");
    setImgPreview(NoImg);
    setOpenDialogAdd(true);
  };

  function handleClickOpenEdit(id, title, titleEN, status, link, picture) {
    setId(id);
    setTitle(title);
    setTitleEN(titleEN);
    setLink(link);
    setStatusPopup(status)
    setImgPreview(url + "/repo/" + picture);
    setOpenDialogEdit(true);
  };


  // API data

  let sendData = new FormData()
  sendData.append('title', title);
  sendData.append('titleEN', titleEN);
  sendData.append('picture', img);
  sendData.append('link', link);
  sendData.append('create_by', localStorage.id);
  sendData.append('approved', []);
  sendData.append('status', "Baru");

  const getData = async () => { 
    let res = await axios.get(url + '/private/popup', { headers })
    let response = await res.data
    setRows(response)
    setData(response)
   }

  const postData = () => { 
    if (title === "") {
      setOpenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!");
      setMessageError("Data Title wajib diisikan")
    }
    else if (img === "") {
      setOpenAlert(true);
      setTitleAlert("Periksa Data isian Anda !!");
      setMessageError("Data Image Foto wajib dimasukkan")
    }
    else {
      axios.post(url + "/private/popup/create", sendData, { headers }).then(res => {
        setOpenDialogAdd(false);
        getData();
      })
    }
   }

  const editData = (id) => { 
    let editData = new FormData()
    editData.append("title", title);
    editData.append("titleEN", titleEN);
    editData.append("picture", img);
    editData.append('link', link);
    editData.append("create_by", localStorage.id);
    editData.append("approved", []);
    editData.append("status", "Telah diedit");

    axios.put(url + "/private/popup/" + id, editData, { headers }).then((res) => {
      console.log('res', res.data)
      setOpenDialogEdit(false);
      getData();
    });
   }

  function editDataForPublish(id) {

    const status = _.filter(data,['status','Publish']);
    console.log("Data Publish ", status.length)

    if (status.length == 1 && (statusPopup === 'Baru' || statusPopup === 'Telah diedit')) {
      setOpenAlert(true)
      setTitleAlert("Periksa Jumlah Data Yang Sudah di Publish !!");
      setMessageError("Maksimal 1 Data yang di Publish")
    }
    else {
      let dataPublish = new FormData();
      dataPublish.append("title", title);
      dataPublish.append("titleEN", titleEN);
      dataPublish.append("picture", img);
      dataPublish.append('link', link);
      dataPublish.append("create_by", localStorage.id);
      dataPublish.append("approved", []);
      dataPublish.append("status", "Publish");

      axios.put(url + "/private/popup/" + id, dataPublish, { headers }).then(() => {
        setOpenDialogEdit(false);
        getData();
      });
    }
  }

  function deleteData(id) {
    axios.delete(url + "/private/popup/" + id, { headers }).then(res => {
      setOpenDialogEdit(false);
      getData();
    });
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log('sendData ', sendData)
  // const status = _.filter(data,['status','Publish']);
  // console.log("Num Publish ", status)
  return (
    <>
    <PageTitle
      title='Konten Popup'
      button='Tambah Konten'
      click={handleClickOpenAdd}
    />

    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Gambar</TableCell>
                <TableCell>Judul</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Typography>{page * rowsPerPage + (index + 1)}</Typography>
                    </TableCell>
                    <TableCell>
                      <img 
                        src={url + '/repo/' + item.picture}
                        style={{ width: 80, height: 75 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography> Judul : {item.title} </Typography>
                      <Typography> Title : {item.titleEN} </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.status}</Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleClickOpenEdit(
                          item._id,
                          item.title,
                          item.titleEN,
                          item.status,
                          item.link,
                          item.picture,
                        )}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination 
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>

    {/* Dialog Add */}
    <Dialog 
      open={openDialogAdd}
      close={handleCloseDialog}
      cancel={handleCloseDialog}
      confirm={() => postData()}
      valueCancel='Batalkan'
      title='Tambah Konten'
      valueConfirm='Tambah'
      content={
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField 
              label='Judul'
              name='title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label='Title'
              name='title'
              value={titleEN}
              onChange={(e) => setTitleEN(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Link"
              // style={{width:'100%'}}
              // margin="normal"
              value={link}
              onChange={event => setLink(event.target.value)}
              name="content"
            />
          </Grid>
          <Grid item xs={12}>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{ marginLeft: 20 }}
            />
            {img.name}
          </Grid>
          <Grid item xs={12}>
            Upload Image :
            <input
              accept="image/*"
              // className={classes.input}
              ref={imgRef}
              multiple
              type="file"
              onChange={e => handleImage(e)}
            />
            <Button color='primary' variant='contained' onClick={handleInputFile}>
              <Typography sx={{ color: '#ffffff' }}>Upload</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    />


    {/* Dialog Edit */}
    <Dialog 
      open={openDialogEdit}
      close={handleCloseDialog}
      title='Form Edit'
      content={
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField 
              label='Judul'
              name='title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label='Title'
              name='title'
              value={titleEN}
              onChange={(e) => setTitleEN(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Link"
              // style={{width:'100%'}}
              // margin="normal"
              value={link}
              onChange={event => setLink(event.target.value)}
              name="content"
            />
          </Grid>
          <Grid item xs={12}>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{ marginLeft: 20 }}
            />
            {img.name}
          </Grid>
          <Grid item xs={12}>
            Upload Image :
            <input
              accept="image/*"
              // className={classes.input}
              ref={imgRef}
              multiple
              type="file"
              onChange={e => handleImage(e)}
            />
            <Button color='primary' variant='contained' onClick={handleInputFile}>
              <Typography sx={{ color: '#ffffff' }}>Upload</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => editData(id)} style={{ border: '2px solid #78f056', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Simpan</Typography>
            </Button>
            <Button onClick={() => editDataForPublish(id)} style={{ border: '2px solid #78f056', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Publish</Typography>
            </Button>
            <Button onClick={handleCloseDialog} style={{ border: '2px solid #6f9ae8', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Batalkan</Typography>
            </Button>
            <Button onClick={() => deleteData(id)} style={{ border: '2px solid #de1200', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Hapus</Typography>
            </Button>
          </Grid>
        </Grid>

        
      }
    />

    <Dialog
      open={openAlert}
      close={() => setOpenAlert(false)}
      title={titleAlert}
      content={
        <Typography>{messageError}</Typography>
      }
      cancel={() => setOpenAlert(false)}
      confirm={() => setOpenAlert(false)}
      valueConfirm={"Oke"}
      valueCancel={"Tutup"}
    />
    </>
  )
}

export default Popup