import React from "react";
import { Button,Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";
import orange from '@material-ui/core/colors/orange';

const buttonColor = orange[600];

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <div>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      </div>
      <div>

      <div>
      <Grid container>
      <div style={{marginRight:15}}>
        {props.filter}
      </div>
      <Typography className={classes.statustxt} variant="h3" size="sm">
        {props.status}
      </Typography>
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="primary"
          onClick={props.click}
        >
          {props.button}
        </Button>
      )}
      </Grid>
      </div>
    </div>
  </div>
  );
}
