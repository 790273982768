import React, { useState , useEffect } from "react";
import {
  Grid, 
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  Button,
} from "@material-ui/core";

export default function DialogModal(props) {

  return (
    <>
      {/* modal  */}
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{padding:0,paddingTop:10,paddingRight:24,paddingLeft:24}}>{props.title}</DialogTitle>
        <DialogContent>
              {props.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.cancel}>
            <Typography style={{textTransform:'none'}}>{props.valueCancel === ""? "Batal" : props.valueCancel}</Typography>
          </Button>
          <Button onClick={props.confirm}>
            <Typography style={{textTransform:'none'}}>{props.valueConfirm}</Typography>
          </Button>
          
        </DialogActions>
      </Dialog>
    </>
  );
}
