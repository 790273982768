import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  TablePagination,
  FormControl,
  Divider
} from "@material-ui/core";


// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import axios from "axios";
import Dialog from "../Components/Dialog/Dialog";

import _ from "lodash";

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import SearchIcon from '@material-ui/icons/Search';
import "./TreeviewStyles.css"
import { InputAdornment } from "@material-ui/core";
import StaticVar from "../../Config/StaticVar";

export default function Sitemap() {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [title, settitle] = React.useState("");
  const [titleEN, settitleEN] = React.useState("");
  const [baseUrl, setbaseUrl] = React.useState("");
  const [link, setLink] = React.useState("");
  const [category, setcategory] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const [position, setposition] = React.useState("");
  const [parent, setparent] = React.useState("");
  const [dataPages, setDataPages] = React.useState([]);
  const [dataDocument, setDataDocument] = React.useState([]);
  const [dataParent, setDataParent] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  var [openAlert, setopenAlert] = useState(false);
  var [titleAlert, setTitleAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [idSiteMap, setIdSiteMap] = useState("");

  const handleClickOpenAdd = () => {
    clearText();
    setOpenModalAdd(true);
  };

  const clearText = () => {
    setIdSiteMap("");
    settitle("");
    settitleEN("")
    setcategory("");
    setLink("");
    setIndex("");
    setbaseUrl("");
    setposition("");
    setparent("");
  };

  function handleClickOpenEdit(
    id,
    title,
    titleEN,
    category,
    parent,
    link,
    baseurl,
    position,
    index,
  ) {
    setIdSiteMap(id);
    settitle(title);
    settitleEN(titleEN)
    setcategory(category);
    setparent(parent);
    setLink(link);
    setbaseUrl(baseurl);
    setposition(position);
    setIndex(index);
    setOpenModalEdit(true);
  }

  function handleClickOpenDelete(id, title) {
    // if (
    //   title === "Beranda" ||
    //   title === "Berita & Galeri" ||
    //   title === "Berita" ||
    //   title === "Galeri Foto" ||
    //   title === "Video dan Live Streming" ||
    //   title === "Pertandingan" ||
    //   title === "Layanan & Dukungan"
    // ) {
    //   setTitleAlert("Maaf Halaman Ini Tidak Bisa Di Hapus !!");
    //   setmessageError("Halaman " + title + " ini adalah halaman default");
    //   setopenAlert(true);
    // } else {
      setIdSiteMap(id);
      settitle(title);
      settitleEN(titleEN)
      setOpenModalDelete(true);
    // }
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };

  const getData = async () => {
    let res = await axios.get(url + "/private/sitemap", { headers });
    let response = await res.data;
    setRows(response);
    setData(response);
  };

  const getDataParent = async () => {
    let res = await axios.get(url + "/private/sitemap", { headers });
    let response = await res.data;

    var dataparent = _.filter(response, function(item) {
      return item.parent == "Tanpa Parent";
    });
    setDataParent(dataparent);
  };

  const getDataPages = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;
    setDataPages(response);
  };

  const getDataDocument = async () => {
    let res = await axios.get(url + "/private/document", { headers });
    let response = await res.data;
    setDataDocument(response);
  };

  function postData() {
    let sendData = {
      title: title,
      titleEN: titleEN,
      category: category,
      link: category === "Redirect" ? link : "",
      setDropDown: false,
      base_URL: category === "Laman" ? "/pages/" + baseUrl : category === "Document" ? "/document/" + baseUrl : category === "Hanya Menu" ? baseUrl : "",
      parent: parent,
      position: position,
      index: index,
      fullWidth: fullWidth,
    };
    if (category === ""){
      setopenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!");
      setmessageError("Data Kategori wajib diisikan")
    } 
    else if(parent === "") {
      setopenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!");
      setmessageError("Data Parent wajib diisikan")
    } 
    // else if(baseUrl === "" && category !== "Redirect" && link !== "") {
    //   setopenAlert(true)
    //   setTitleAlert("Periksa Data isian Anda !!");
    //   setmessageError("Data Base Url wajib diisikan")
    // } 
    else {
      axios
        .post(url + "/private/sitemap/create", sendData, { headers })
        .then(res => {
          getData();
          setOpenModalAdd(false);
      });
    }
  }

  //for reload page javascript  window.location.reload();

  function editData(id) {
    let editdata = {
      title: title,
      titleEN,
      category: category,
      link: category === "Redirect" ? link : "",
      setDropDown: false,
      base_URL: category === "Laman" ? "/pages/" + baseUrl : category === "Document" ? "/document/" + baseUrl : category === "Hanya Menu" ? baseUrl : "",
      parent: parent,
      position: position,
      index: index,
      fullWidth: fullWidth,
    };

    axios
      .put(url + "/private/sitemap/" + id, editdata, { headers })
      .then(() => {
        getData();
        setOpenModalEdit(false);
    });
  }

  function deleteData(id, title) {
    axios.delete(url + "/private/sitemap/" + id, { headers }).then(res => {
      getData();
      setOpenModalDelete(false);
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      await getData();
      await getDataParent();
    }
    fetchData()
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getDataPages();
      await getDataDocument();
    }
    fetchData()
  }, []);

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("")

  const handleSearch = (event) => {
   var searchQuery = event.target.value.toString().toLowerCase();
   const result = data.filter((e)=>{
     return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
   })
   setRows(result)    
   setSearch(searchQuery)
 }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const klikitem = (id) => {
    const getButton = document.getElementById("btn"+id)
    const getChild = document.getElementById("child"+id)
    console.log(getChild.className)
    getButton.innerText = getButton.innerText === "HIDE" ? "EXPAND" : "HIDE"
    getChild.style = getButton.innerText === "HIDE" ? "display:block" : "display:none"
  }

  return (
    <>
      <PageTitle
        title="Peta Situs"
        button="Tambah Peta Situs"
        click={handleClickOpenAdd}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                  <InputAdornment position='start'>
                      <SearchIcon />
                  </InputAdornment>
              ),
              classes : {input : classes.textSearch}
            }}
          />
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>

            <List component="nav" className={classes.root}>
              {
                  rows.filter(x=>x.parent==="Tanpa Parent").map(item=>{
                      var treeDetail =  rows.filter(x=>x.parent === item._id)
                      return(
                          <div key={item._id}>
                              <ListItem  button>
                                  {/* <ListItemIcon>
                                      <SendIcon />
                                  </ListItemIcon> */}
                                  <ListItemText primary={
                                      <>
                                          <p style={{marginBottom:0}}>Judul : {item.title}</p>
                                          <small>Title : {item.titleEN}</small>
                                      </>
                                  } />
                                  <ListItemText secondary={item.category} />
                                  {
                                      treeDetail.length > 0 ? <Button id={"btn"+item._id} onClick={()=>klikitem(item._id)} variant="contained" style={{marginRight:10}}>EXPAND</Button> : null
                                  }
                                   <Button
                                      variant="contained" style={{marginLeft:10}}
                                      onClick={() =>
                                        handleClickOpenEdit(
                                          item._id,
                                          item.title,
                                          item.titleEN,
                                          item.category,
                                          item.parent,
                                          item.link,
                                          item.base_URL,
                                          item.position,
                                          item.index,
                                        )
                                      }
                                    >
                                      <Typography>
                                        Edit
                                      </Typography>
                                    </Button>
                                    <Button variant="contained" style={{marginLeft:10}}
                                      onClick={() =>
                                        handleClickOpenDelete(item._id, item.title)
                                      }
                                    >
                                      <Typography>
                                        Delete
                                      </Typography>
                                    </Button>
                                  
                              </ListItem>
                              <Divider/>
                              {/* <Collapse  timeout="auto" unmountOnExit> */}
                              <div id={"child"+item._id} style={{display:'none'}}>
                                  {
                                    treeDetail.map(item2=>{
                                      return(
                                        <div key={item2._id}>
                                            <List component="div" disablePadding style={{backgroundColor:'#EFEFEF'}}>
                                              <ListItem button className={classes.nested}>
                                                <ListItemIcon>
                                                <StarBorder />
                                                </ListItemIcon>
                                                <ListItemText primary={
                                                      <>
                                                          <p style={{marginBottom:0}}>Judul : {item2.title}</p>
                                                          <small>Title : {item2.titleEN}</small>
                                                      </>
                                                  } />

                                                <Button
                                                  variant="contained" style={{marginLeft:10}}
                                                  onClick={() =>
                                                    handleClickOpenEdit(
                                                      item2._id,
                                                      item2.title,
                                                      item2.titleEN,
                                                      item2.category,
                                                      item2.parent,
                                                      item2.link,
                                                      item2.base_URL,
                                                      item2.position,
                                                      item2.index,
                                                    )
                                                  }
                                                >
                                                  <Typography>
                                                    Edit
                                                  </Typography>
                                                </Button>
                                                <Button variant="contained" style={{marginLeft:10}}
                                                  onClick={() =>
                                                    handleClickOpenDelete(item2._id, item2.title)
                                                  }
                                                >
                                                  <Typography>
                                                    Delete
                                                  </Typography>
                                                </Button>
                                              </ListItem>
                                            </List>
                                            <Divider/>
                                        </div>
                                      )
                                    })
                                  }
                              </div>
                              {/* </Collapse> */}
                          </div>
                      )
                  })
              }
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* modal add */}
      <Dialog
        open={openModalAdd}
        close={handleClose}
        title={"Form Tambah Peta Situs"}
        content={
          <div style={{ width: 450, height: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={category}
                  onChange={event => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value={"Hanya Menu"}>Hanya Menu</MenuItem>
                  <MenuItem value={"Laman"}>Laman</MenuItem>
                  <MenuItem value={"Redirect"}>Redirect</MenuItem>
                  {/* <MenuItem value={"Document"}>Document</MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={parent}
                  onChange={event => {
                    setparent(event.target.value);
                  }}
                >
                  <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
                  {dataParent.map(item => (
                    <MenuItem key={item} value={item._id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Judul"
                  className={classes.formContainer}
                  margin="normal"
                  value={title}
                  onChange={event => {
                    settitle(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Title"
                  className={classes.formContainer}
                  margin="normal"
                  value={titleEN}
                  onChange={event => {
                    settitleEN(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
              {category === "Laman" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Laman
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={event => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataPages.map(item => (
                        <MenuItem key={item} value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {category === "Document" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Document
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={event => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataDocument.map(item => (
                        <MenuItem key={item} value={item.title}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {category === "Redirect" ? (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={link}
                    onChange={event => {
                      setLink(event.target.value);
                    }}
                    name="name"
                  />
                </Grid>
              ) : 
              (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={baseUrl}
                    onChange={event => {
                      setbaseUrl(event.target.value);
                    }}
                    name="name"
                  />
                </Grid>
              )
              }
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={position}
                  onChange={event => {
                    setposition(event.target.value);
                  }}
                >
                  <MenuItem value={"Header"}>Header</MenuItem>
                  <MenuItem value={"Footer"}>Footer</MenuItem>
                  <MenuItem value={"Header & Footer"}>Header & Footer</MenuItem>
                  <MenuItem value={"Button Header"}>Button Header</MenuItem>
                  <MenuItem value={"Button Header & Footer"}>Button Header & Footer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Urutan"
                  className={classes.formContainer}
                  margin="normal"
                  type="number"
                  value={index}
                  onChange={event => {
                    setIndex(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            </Grid>
          </div>
        }
        cancel={handleClose}
        // confirm={() => postData(category)}
        confirm={() => postData()}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

      {/* modal edit*/}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Edit Peta Situs"}
        content={
          <div style={{ width: 450, height: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={category}
                  onChange={event => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value={"Hanya Menu"}>Hanya Menu</MenuItem>
                  <MenuItem value={"Laman"}>Laman</MenuItem>
                  <MenuItem value={"Redirect"}>Redirect</MenuItem>
                  {/* <MenuItem value={"Document"}>Document</MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={parent}
                  onChange={event => {
                    setparent(event.target.value);
                  }}
                >
                  <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
                  {dataParent.map(item => (
                    <MenuItem key={item} value={item._id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6}  xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Judul"
                  className={classes.formContainer}
                  margin="normal"
                  value={title}
                  onChange={event => {
                    settitle(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Title"
                  className={classes.formContainer}
                  margin="normal"
                  value={titleEN}
                  onChange={event => {
                    settitleEN(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
              {category === "Laman" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Laman
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={event => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataPages.map(item => (
                        <MenuItem key={item} value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {category === "Document" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Document
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={event => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataDocument.map(item => (
                        <MenuItem key={item} value={item.title}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {
                category === "Redirect" ? (
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <TextField
                      label="Base URL atau Link"
                      className={classes.formContainer}
                      margin="normal"
                      value={link}
                      onChange={event => {
                        setLink(event.target.value);
                      }}
                      name="name"
                    />
                  </Grid>
                ) : 
                (
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <TextField
                      label="Base URL atau Link"
                      className={classes.formContainer}
                      margin="normal"
                      value={baseUrl}
                      onChange={event => {
                        setbaseUrl(event.target.value);
                      }}
                      name="name"
                    />
                  </Grid>
                )
              }
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={position}
                  onChange={event => {
                    setposition(event.target.value);
                  }}
                >
                  <MenuItem value={"Header"}>Header</MenuItem>
                  <MenuItem value={"Footer"}>Footer</MenuItem>
                  <MenuItem value={"Header & Footer"}>Header & Footer</MenuItem>
                  <MenuItem value={"Button Header"}>Button Header</MenuItem>
                  <MenuItem value={"Button Header & Footer"}>Button Header & Footer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Urutan"
                  className={classes.formContainer}
                  margin="normal"
                  type="number"
                  value={index}
                  onChange={event => {
                    setIndex(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            </Grid>
          </div>
        }
        cancel={handleClose}
        confirm={() => editData(idSiteMap)}
        valueConfirm={"Edit"}
        valueCancel={"Batalkan"}
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Grid container>
            <Grid item lg={12}>
              <Typography>Halaman dengan Judul : {title}</Typography>
            </Grid>
          </Grid>
        }
        cancel={handleClose}
        confirm={() => deleteData(idSiteMap, title)}
        valueConfirm={"Hapus"}
      />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={titleAlert}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
    </>
  );
}
