import React, { useState , useEffect } from "react";
import {
  Grid, 
  InputAdornment,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  TablePagination,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import InboxIcon from '@material-ui/icons/Inbox';
import SearchIcon from '@material-ui/icons/Search';
import axios from "axios";

import _ from "lodash";

// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import Api from "../../Services/Api";

export default function Category(props) {
  var classes = useStyles();

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalChooseUserContributor, setOpenModalChooseUserContributor] = useState(false);
  const [openModalChooseUserEditor, setOpenModalChooseUserEditor] = useState(false);
  const [openModalChooseUserRedactor, setOpenModalChooseUserRedactor] = useState(false);

  const [chooseredactor, setchooseredactor] = useState([])
  const [choosekontributor, setchoosekontributor] = useState([])
  const [chooseeditorm, setchooseeditor] = useState([])

  var [idCategory, setIdCategory] = useState("");
  var [nameCategory, setNameCategory] = useState("");
  var [nameENCategory, setNameENCategory] = useState("");
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [dataOrganization, setDataOrganization] = useState([])

  const [usersEditor, setUsersEditor] = useState([]);
  const [usersContributor, setUsersContributor] = useState([]);
  const [usersRedactor, setUsersRedactor] = useState([]);

  const handleClickOpenAdd = () => {
    setOpenModalAdd(true);
  };

  const getDataOrganization = () => { 
    Api.getOrganization()
      .then((res)=>{
        const data = res.data
        const dataSection = data.filter((val) => val.level === "Seksi")
        // console.log("Seksi ", dataSection);
        setDataOrganization(dataSection)
      })
   }

  function handleClickOpenEdit(id,name){
    setIdCategory(id);
    setNameCategory(name);
    setOpenModalEdit(true);
  };

  function handleClickOpenDelete(id,name) {
    setIdCategory(id);
    setNameCategory(name);
    setOpenModalDelete(true);
  };

  function handleClickOpenChooseUser(x, _id){
    if(x === "kontributor"){
      setIdCategory(_id);
      setOpenModalChooseUserContributor(true);
    }
    else if(x === "editor"){
      setIdCategory(_id);
      setOpenModalChooseUserEditor(true);
    }
    else{
      setIdCategory(_id);
      setOpenModalChooseUserRedactor(true);
    }
     
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
    setOpenModalChooseUserContributor(false);
    setOpenModalChooseUserEditor(false);
    setOpenModalChooseUserRedactor(false);
  };

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const getData = () => {
    // let res = await axios.get(url+"/private/news_categories",{headers});
    // let response = await res.data
    axios.get(url+"/private/news_categories",{headers}).then(async(res)=>{
      setRows(res.data)
      setData(res.data)
    })
    
  }

  const getDataUsers = async () => {
    let res = await axios.get(url+"/private/users",{headers});
    let response = await res.data

    var dataUserEditor = _.filter(response, function(item){
      return item != null && item.user_access == "editor";
    });
    setUsersEditor(dataUserEditor);

    var dataUserContributor = _.filter(response, function(item){
      return item != null && item.user_access == "kontributor";
    });
    dataUserContributor.forEach(item=>{
      item["check_status"] = false
    })
    // console.log('dataUserContributor',dataUserContributor)
    setUsersContributor(dataUserContributor);

    var dataUserRedaktor = _.filter(response, function(item){
      return item != null && item.user_access == "redaktor";
    });
    setUsersRedactor(dataUserRedaktor);
  }

  function handleInput(e){
    setNameCategory([e.target.name]= e.target.value)
    // console.log('data post : ',nameCategory);
  }

  let sendData = {
    contributor:[],
    editor:[],
    redactor:[],
    name: nameCategory,
    nameEN : nameENCategory
  }

  function postData(){
    axios.post(url+"/private/news_categories/create",sendData,{headers}).then(()=> {
      // setData([...data,res.data]);
      getData();
      setOpenModalAdd(false);
    })
  }

  //for reload page javascript  window.location.reload();

  function editData(id){

    axios.put(url+"/private/news_categories/"+id,sendData,{headers}).then(async (res)=> {
      //await getData();
      await changedatarow(res.data)
      setOpenModalEdit(false);
    })
  }

  function deleteData(id) {
    axios.delete(url+"/private/news_categories/"+id,{headers}).then(()=> {
      getData();
      setOpenModalDelete(false);
    })
  }

  async function deleteUser(x,id){
    let res = await axios.get(url+"/private/news_categories",{headers});
    let response = await res.data
    response.forEach((item)=>{
      if(x === "contributor"){
        item.contributor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/news_categories/"+item._id+"/contributor/"+val._id,{headers}).then(async(res) => {
              //console.log('delete_user', res.data);

              //setRows([]);
              //await getData();
              var datarows = rows;
              
              datarows.forEach((element,index)=>{
                if(item._id === element._id){
                  var datacontributor = item.contributor;
                  var newdata = []
                  datacontributor.forEach((element2,index2)=>{
                    var data = element2;                    
                    if(data._id !== val._id){
                      newdata.push(data);
                    }
                  })
                  datarows[index].contributor = {...newdata};
                }
              })
              //setRows(datarows.slice())
              //setRows(datarows);
              console.log('delete user',datarows)
            });
          }
        })
      }
      else if(x === "editor"){
        item.editor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/news_categories/"+item._id+"/editor/"+val._id,{headers}).then(async() => {
              await getData();
            });
          }
        })
      }
      else{
        item.redactor.forEach(val=>{
          if(val._id === id){
            axios.delete(url+"/private/news_categories/"+item._id+"/redactor/"+val._id,{headers}).then(async() => {
              await getData();
            });
          }
        })
      }
    })
  }

  useEffect(() => {
    const req1 = axios.get(url+"/private/news_categories",{headers});
    const req2 = axios.get(url+"/private/users",{headers});

    axios.all([req1,req2]).then(axios.spread((...responses) => {
      const response1 = responses[0]
      const response2 = responses[1]
      setRows(response1.data)
      setData(response1.data)

      var dataUserEditor = _.filter(response2.data, function(item){
        return item != null && item.user_access == "editor";
      });
      setUsersEditor(dataUserEditor);
  
      var dataUserContributor = _.filter(response2.data, function(item){
        return item != null && item.user_access == "kontributor";
      });
      dataUserContributor.forEach(item=>{
        item["check_status"] = false
      })
      // console.log('dataUserContributor',dataUserContributor)
      setUsersContributor(dataUserContributor);
  
      var dataUserRedaktor = _.filter(response2.data, function(item){
        return item != null && item.user_access == "redaktor";
      });
      setUsersRedactor(dataUserRedaktor);
    })).catch(errors => {
      // react on errors.
    })


    getDataOrganization()
    // getData();
    // getDataUsers();
    // return () => {
    //   getData();
    //   getDataUsers();
    // }
  }, []);


  /** Table Pagination */
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function changedatarow(newdata){
    var datarows = rows;
    datarows.forEach((element,index)=>{
      if(newdata._id === element._id){
        datarows[index] = newdata;
      }
    })
  }

  
  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e)=>{
      return e.name.toString().toLowerCase().indexOf(searchQuery) !== -1;
    })
    setRows(result)    
    setSearch(searchQuery)
}

  // function changedatarow(deletedata){
  //   var datarows = rows;
  //   datarows.forEach((element,index)=>{
  //     if(newdata._id === element._id){
  //       datarows[index] = newdata;
  //     }
  //   })
  // }

  return (
    <>
      <PageTitle title="Kategori" button="Tambah Kategori" click={handleClickOpenAdd}
      
      filter={
        <TextField
          variant="outlined"
          onChange={handleSearch}
          value={search}
          className={classes.searchField}
          placeholder="Cari.."
          InputProps={{
            startAdornment: (
                <InputAdornment position='start'>
                    <SearchIcon />
                </InputAdornment>
            ),
            classes : {input : classes.textSearch}
          }}
        />
      }
      
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
              <TableRow>
                    <TableCell style={{ textAlign: 'center',width:'4%'}}>No</TableCell>
                    <TableCell style={{ textAlign: 'left',width: '17%' }}>Kategori</TableCell>
                    <TableCell style={{ textAlign: 'center', width: '70%' }}>Pilih User</TableCell>
                    <TableCell style={{ textAlign: 'center',width:'10%'}}>Aksi</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {
                  stableSort(rows, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                  <TableRow key={item._id}>
                      
                        <TableCell style={{width:'3%'}}>
                          <Typography className={classes.txtContentTable}>{(page*rowsPerPage)+(index+1)}</Typography>
                        </TableCell>
                      <TableCell style={{width:'17%'}}>
                        <Typography>Kategori : {item.name}</Typography>
                        {/* <Typography>Title : {item.nameEN}</Typography> */}
                      </TableCell>
                        <TableCell style={{width:'70%'}}>
                          <Grid container spacing={3}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                              Kontributor
                              <Button className={classes.chooseUserBtn} onClick={()=>{
                                 setchoosekontributor(item.contributor)
                                handleClickOpenChooseUser("kontributor", item._id)}
                              }><Typography className={classes.chooseUserTxt}>Pilih Kontributor</Typography></Button> 
                              {
                                item.contributor.map((val, index2)=>(
                                  <List key={index2} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{val.email}</Typography>
                                      {/* <Button style={{minWidth:0}} onClick={()=>deleteUser("contributor",val._id)}><HighlightOffIcon style={{color:'red'}}/></Button> */}
                                    </ListItem>
                                  </List>
                                ))
                              }   
                            </Card>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                              Editor
                              <Button className={classes.chooseUserBtn} onClick={()=>{
                                setchooseeditor(item.editor)
                                handleClickOpenChooseUser("editor",item._id)}
                              }><Typography className={classes.chooseUserTxt}>Pilih Editor</Typography></Button>
                              {
                                item.editor.map((val, index3)=>(
                                  <List key={index3} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{val.email}</Typography>
                                      {/* <Button style={{minWidth:0}} onClick={()=>deleteUser("editor",val._id)}><HighlightOffIcon style={{color:'red'}}/></Button> */}
                                    </ListItem>
                                  </List>
                                ))
                              }   
                            </Card>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Card className={classes.containerCard}>
                            Publisher
                              <Button className={classes.chooseUserBtn} onClick={()=>{
                                setchooseredactor(item.redactor)
                                handleClickOpenChooseUser("redaktor",item._id)}
                              }><Typography className={classes.chooseUserTxt}>Pilih Publisher</Typography></Button>
                              {
                                item.redactor.map((val, index4)=>(
                                  <List key={index4} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                                    <ListItem button>
                                      <ListItemIcon style={{minWidth:0}}>
                                        <InboxIcon style={{marginRight:10}}/>
                                      </ListItemIcon>
                                      <Typography style={{fontSize:15}}>{val.email}</Typography>
                                      {/* <Button style={{minWidth:0}} onClick={()=>deleteUser("redactor",val._id)}><HighlightOffIcon style={{color:'red'}}/></Button> */}
                                    </ListItem>
                                  </List>
                                ))
                              }    
                            </Card>
                          </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell style={{width:'10%'}}>
                          <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Button className={classes.btnAction} onClick={() => handleClickOpenEdit(item._id,item.name)}><Typography className={classes.txtAction}>Edit</Typography></Button>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Button className={classes.btnAction} onClick={() => handleClickOpenDelete(item._id,item.name)}><Typography className={classes.txtAction}>Hapus</Typography></Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search ? rows.length : data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

{/* <==========================================================================================================================================> */}
      
      {/* modal add */}
      <Dialog
        open={openModalAdd}
        close={handleClose}
        title={"Form Tambah Kategori Berita"}
        content={
          <Grid container spacing={5}>
            <Grid item xs={12}>
                {/* <InputLabel htmlFor="id-section">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={nameCategory}
                  onChange={e => handleInput(e)}
                  name="section"
                >
                  {dataOrganization?.map((item, index) => (
                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select> */}
                <TextField
                label="Kategori"
                className={classes.formContainer}
                margin="normal"
                value={nameCategory}
                onChange={e => handleInput(e)}
                name="name"
              />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                label="Section"
                className={classes.formContainer}
                margin="normal"
                value={nameENCategory}
                onChange={e => setNameENCategory(e.target.value)}
                name="name"
              />
              </Grid> */}
          </Grid>
        
        }
        cancel={handleClose}
        confirm={() => postData()}
        valueConfirm={"Tambah"}
      />

      {/* modal edit */}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Edit Kategori Berita"}
        content={
          <Grid container spacing={5}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
              label="Kategori"
              className={classes.formContainer}
              margin="normal"
              value={nameCategory}
              onChange={e => handleInput(e)}
              name="name"
              />
              {/* <InputLabel htmlFor="id-section">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={nameCategory}
                  onChange={e => handleInput(e)}
                  name="section"
                >
                  {dataOrganization?.map((item, index) => (
                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select> */}
            </Grid>
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
              label="Kategori"
              className={classes.formContainer}
              margin="normal"
              value={nameENCategory}
              onChange={e => setNameENCategory(e.target.value)}
              name="name"
              />
            </Grid> */}
          </Grid>
        }
        cancel={handleClose}
        confirm={()=>editData(idCategory)}
        valueConfirm={"Edit"}
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>{nameCategory}</Typography>
          </Grid>
          </Grid>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idCategory)}
        valueConfirm={"Hapus"}
      />

      {/* modal choose user contributor */}
      <Dialog
        open={openModalChooseUserContributor}
        close={handleClose}
        title={"Form Pilih Kontributor"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            usersContributor.map(item=>(
              <List key={item} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.email}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersContributor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                        }
                      });
                      setUsersContributor(usersContributor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
            ))
          }
          
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersContributor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          console.log(JSON.stringify(datastack));
          axios.put(url+'/private/news_categories/contributor/'+idCategory, {
            contributor : datastack
          }, {headers}).then(async(res)=>{    
            
            await changedatarow(res.data);
            setOpenModalChooseUserContributor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

      {/* modal choose user editor */}
      <Dialog
        open={openModalChooseUserEditor}
        close={handleClose}
        title={"Form Pilih Editor"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            usersEditor.map(item=>(
              <List key={item} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.email}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersEditor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                        }
                      });
                      setUsersEditor(usersEditor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
            ))
          }
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersEditor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          axios.put(url+'/private/news_categories/editor/'+idCategory, {
            editor : datastack
          }, {headers}).then(async(res)=>{
            await changedatarow(res.data);
            //await getData();
            setOpenModalChooseUserEditor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

{/* modal choose user redactor*/}

      <Dialog
        open={openModalChooseUserRedactor}
        close={handleClose}
        title={"Form Pilih Publisher"}
        content={
        <Grid item lg={12} md={12} sm={12} xs={12}>
        {
          usersRedactor.map(item=>
            {
              // var check = item.check_status ? item.check_status : false
              // if(chooseredactor.filter(x=>x._id === item._id).length> 0){
              //   usersRedactor.forEach(element => {
              //     if(element._id === item._id){
              //       element.check_status = true
              //     }
              //   });
              //   //setUsersRedactor(usersRedactor.slice());
              // }
              //var checkdata =  chooseredactor.filter(x=>x._id === item._id).length> 0 ? true : false
              return(
              <List key={item} component="nav" aria-label="main mailbox folders" className={classes.listUser}>
                <ListItem button>
                  <ListItemIcon style={{minWidth:0}}>
                    <InboxIcon style={{marginRight:10}}/>
                  </ListItemIcon>
                  <Typography style={{fontSize:15,paddingLeft:0}}>{item.email}</Typography>
                  <Checkbox
                    style={{position:'absolute',right:0}}
                    checked={item.check_status}
                    onChange={ ()=>{
                      usersRedactor.forEach(element => {
                        if(element._id === item._id){
                          element.check_status = !element.check_status
                          // console.log('delete', !element.check_status)
                        }
                      });
                      //setUsersRedactor(usersRedactor.slice());
                    }}
                    value={item.check_status}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </ListItem>
              </List>
              )
            })
        }
        </Grid>
        }
        cancel={handleClose}
        confirm={() => {
          var datastack = [];
          usersRedactor.forEach(element=>{
            if(element.check_status){
              datastack.push(element);
            }
          })
          axios.put(url+'/private/news_categories/redactor/'+idCategory, {
            redactor : datastack
          }, {headers}).then(async(res)=>{
            await changedatarow(res.data);
            //await getData();
            setOpenModalChooseUserRedactor(false);
          })
        }}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />
    </>
  );
}
