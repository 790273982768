import axios from 'axios';
import StaticVar from '../Config/StaticVar';

// ===> api create
const api = axios.create({
  baseURL: StaticVar.URL_API,
  // timeout: 100000,
  headers: {},
  // json: true
});

// ===> api interceptors
api.interceptors.request.use(
  async (config) => {
    config.headers['x-access-token'] = await localStorage.token
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// ===> api list function request

const getDocument = (query) => api.get('/private/document', query);
const getDocumentById = (id) => api.get('/private/document/' + id);
const postDocument = (data) => api.post('/private/document/create', data);
const putDocument = (id, data) => api.put('/private/document/' + id, data);
const postUploadDocument = (data) => api.post('/private/document_upload', data);
const getUsers = () => api.get('/private/users');
const getOrganization = () => api.get('/private/organization')
const postOrganization = (data) => api.post('/private/organization/create', data)
const putOrganization = (id, data) => api.put('/private/organization/' + id, data)
const getOneOrganization = (id) => api.get('/private/organization/' + id)
const deleteOrganization = (id) => api.delete('/private/organization/' + id)

// Categories
const get_news_categories = () => api.get('/private/news_categories')
const get_articles_categories = () => api.get('/private/articles_categories')
const get_photos_categories = () => api.get('/private/photos_categories')
const get_videos_categories = () => api.get('/private/videos_categories')
const get_theories_categories = () => api.get('/private/theories_categories')

const post_news_categories = (data) => api.post('/private/news_categories/create', data)
const post_articles_categories = (data) => api.post('/private/articles_categories/create', data)
const post_photos_categories = (data) => api.post('/private/photos_categories/create', data)
const post_videos_categories = (data) => api.post('/private/videos_categories/create', data)
const post_theories_categories = (data) => api.post('/private/theories_categories/create', data)

const put_news_categories = (id, data) => api.put('/private/news_categories/' + id, data)
const put_articles_categories = (id, data) => api.put('/private/articles_categories/' + id, data)
const put_photos_categories = (id, data) => api.put('/private/photos_categories/' + id, data)
const put_videos_categories = (id, data) => api.put('/private/videos_categories/' + id, data)
const put_theories_categories = (id, data) => api.put('/private/theories_categories/' + id, data)

const put_contributor_news_categories = (id, data) => api.put('/private/news_categories/contributor/' + id, data)
const put_contributor_articles_categories = (id, data) => api.put('/private/articles_categories/contributor/' + id, data)
const put_contributor_photos_categories = (id, data) => api.put('/private/photos_categories/contributor/' + id, data)
const put_contributor_videos_categories = (id, data) => api.put('/private/videos_categories/contributor/' + id, data)
const put_contributor_theories_categories = (id, data) => api.put('/private/theories_categories/contributor/' + id, data)

const put_editor_news_categories = (id, data) => api.put('/private/news_categories/editor/' + id, data)
const put_editor_articles_categories = (id, data) => api.put('/private/articles_categories/editor/' + id, data)
const put_editor_photos_categories = (id, data) => api.put('/private/photos_categories/editor/' + id, data)
const put_editor_videos_categories = (id, data) => api.put('/private/videos_categories/editor/' + id, data)
const put_editor_theories_categories = (id, data) => api.put('/private/theories_categories/editor/' + id, data)

const put_publisher_news_categories = (id, data) => api.put('/private/news_categories/redactor/' + id, data)
const put_publisher_articles_categories = (id, data) => api.put('/private/articles_categories/redactor/' + id, data)
const put_publisher_photos_categories = (id, data) => api.put('/private/photos_categories/redactor/' + id, data)
const put_publisher_videos_categories = (id, data) => api.put('/private/videos_categories/redactor/' + id, data)
const put_publisher_theories_categories = (id, data) => api.put('/private/theories_categories/redactor/' + id, data)


const delete_news_categories = (id) => api.delete('/private/news_categories/' + id)
const delete_articles_categories = (id) => api.delete('/private/articles_categories/' + id)
const delete_photos_categories = (id) => api.delete('/private/photos_categories/' + id)
const delete_videos_categories = (id) => api.delete('/private/videos_categories/' + id)
const delete_theories_categories = (id) => api.delete('/private/theories_categories/' + id)

// Dataset
const get_dataset_categories = () => api.get('/private/dataset_categories')
const post_dataset_categories = (data) => api.post('/private/dataset_categories/create', data)
const put_dataset_categories = (id, data) => api.put('/private/dataset_categories/' + id, data)
const delete_dataset_categories = (id) => api.delete('/private/dataset_categories/' + id)

export const apis = {
  getDocument,
  getDocumentById,
  postDocument,
  putDocument,
  postUploadDocument,
  getUsers,
  getOrganization,
  postOrganization,
  putOrganization,
  deleteOrganization,
  getOneOrganization,
  get_news_categories,
  get_articles_categories,
  get_photos_categories,
  get_videos_categories,
  get_theories_categories,
  post_news_categories,
  post_articles_categories,
  post_photos_categories,
  post_videos_categories,
  post_theories_categories,
  put_news_categories,
  put_articles_categories,
  put_photos_categories,
  put_videos_categories,
  put_theories_categories,
  delete_news_categories,
  delete_articles_categories,
  delete_photos_categories,
  delete_videos_categories,
  delete_theories_categories,
  get_dataset_categories,
  post_dataset_categories,
  put_dataset_categories,
  delete_dataset_categories,
  put_contributor_news_categories,
  put_contributor_articles_categories,
  put_contributor_photos_categories,
  put_contributor_videos_categories,
  put_contributor_theories_categories,
  put_editor_news_categories,
  put_editor_articles_categories,
  put_editor_photos_categories,
  put_editor_videos_categories,
  put_editor_theories_categories,
  put_publisher_news_categories,
  put_publisher_articles_categories,
  put_publisher_photos_categories,
  put_publisher_videos_categories,
  put_publisher_theories_categories,
}

export default apis;
