import React, { useEffect, useState } from 'react'

import imageCompression from "browser-image-compression";

import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar"

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import axios from 'axios'
import _ from "lodash";
import { Editor } from "@tinymce/tinymce-react";

const EditFaq = (props) => {
  const classes = useStyles()

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const id = props.match.params._id;

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  }

  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [hashtag, setHashtag] = useState("")
  const [status, setStatus] = useState("")

  const [openAlert, setOpenAlert] = useState(false)
  const [messageError, setMessageError] = useState("")

  const getData = async () => { 
    let res = await axios.get(url + "/private/faq/" + id, { headers })
    let response = await res.data[0]
    console.log("Data ", response)
    console.log("Hashtag ", response.hashtag.join(' '))

    setQuestion(response.question)
    setHashtag(response.hashtag.join(' '))
    setAnswer(response.answer)
    setStatus(response.faq_status)
   }

  const editData = () => { 
    let sendData = new FormData()
    console.log(" Id user ", localStorage.id)
    console.log("Hashtag edit ", hashtag)

    sendData.append('question', question)
    sendData.append('answer', answer)
    sendData.append('hashtag', hashtag)
    sendData.append('create_by', localStorage.id)
    sendData.append('faq_status', "Telah Diedit")

    axios
      .put(url + "/private/faq/" + id, sendData, { headers })
      .then(() => {
        props.history.push("/app/faq");
      });
    
   }

  const publishData = () => { 
    let sendData = new FormData()
    console.log(" Id user ", localStorage.id)

    sendData.append('question', question)
    sendData.append('answer', answer)
    sendData.append('hashtag', hashtag)
    sendData.append('create_by', localStorage.id)
    sendData.append('faq_status', "Publish")
    
    axios
      .put(url + "/private/faq/" + id, sendData, { headers })
      .then(() => {
        props.history.push("/app/faq");
      });
   }

   function handleEditorChange(value) {
    setAnswer(value);
    
  }

   useEffect(()=>{
    getData()
   }, [])
  return (
    <>
    <PageTitle title='Form FAQ' />
    <Dialog
      open={openAlert}
      close={()=>setOpenAlert(false)}
      title="Harap periksa data isian Anda.."
      content={<Typography>{messageError}</Typography>}
      cancel={()=> setOpenAlert(false)}
      confirm={()=>setOpenAlert(false)}
      valueConfirm="Oke"
      valueCancel="Tutup"
    />

    <Grid container spacing={6}>
        <Grid item xs={12}>
        <TextField
          fullWidth
          id="questions"
          label="Pertanyaan"
          value={question}
          required
          multiline
          minRows={4}
          onChange={e => setQuestion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formContainer}
            margin="normal"
            label="Hashtag"
            value={hashtag}
            name="hashtag"
            onChange={event => setHashtag(event.target.value)} />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography>Jawaban</Typography>
          <input
            accept="image/*"
            className={classes.input}
            id="file-editor"
            multiple
            type="file"
            // onChange={e => handleImage(e)}
          />
          <Box className={classes.editorToolbar}>
            <Editor
              apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
              // initialValue={answer}
              value={answer}
              init={{
                file_picker_callback: function(callback, value, meta) {
                  if (meta.filetype == "image") {
                    var input = document.getElementById("file-editor");
                    input.click();
                    input.onchange = function() {
                      var reader = new FileReader();
                      var file = input.files[0];
                      var options = {
                        maxSizeMB: 0.4,
                        maxWidthOrHeight: 3000,
                        useWebWorker: true,
                      };
                      imageCompression(file, options).then(function(
                        compressedFile,
                      ) {
                        reader.onload = function(e) {
                          callback(e.target.result, {
                            alt: compressedFile.name,
                          });
                        };
                        reader.readAsDataURL(compressedFile);
                      });
                    };
                  }
                },
                paste_data_images: true,
                images_upload_handler: function(blobInfo, success, failure) {
                  success(
                    "data:" +
                      blobInfo.blob().type +
                      ";base64," +
                      blobInfo.base64(),
                  );
                },
                branding: false,
                height: 500,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                    "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media table paste code help fullpage",
                    "directionality",
                    "image"
                ],
                toolbar:
                  "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help",
              }}
              // onDragDrop={true}
              onEditorChange={(newValue, editor) =>
                handleEditorChange(newValue, editor)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button 
              onClick={() => props.history.push('/app/faq')}
              style={{
                width: 120,
                border: "2px solid gray",
                marginBottom: 10,
                marginRight: 10,
              }}
              >Batal</Button>
            <Button 
              onClick={editData} 
              style={{
                width: 120,
                border: "2px solid #bf272b",
                color: "#bf272b",
                marginBottom: 10,
                marginRight: 10,
              }}
              >Simpan</Button>
            <Button 
              onClick={publishData} 
              style={{
                width: 120,
                border:'2px solid #bf272b',
                color:'#bf272b',
                marginBottom: 10,
              }}
              >Publish</Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default EditFaq