import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Hidden,
  CircularProgress,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";

import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';

// styles
import useStyles from "./styles";

import { NavLink } from "react-router-dom"
import axios from "axios";

import PageTitle from "../Components/PageTitle/PageTitle";

import Dialog from "../Components/Dialog/Dialog";
import { InputAdornment } from "@material-ui/core";
import { Player, PosterImage, BigPlayButton, LoadingSpinner } from 'video-react';
import StaticVar from "../../Config/StaticVar"
import moment from 'moment'
import "../../../node_modules/video-react/dist/video-react.css"
import Api from '../../Services/Api'

export default function Content(props) {
  const url = StaticVar.URL_API//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("")
  const [data, setData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  var [idNews, setIdNews] = useState("");
  var [titleNews, setTitleNews] = useState("");
  var [loading, setLoading] = useState(false)
  var [users, setusers] = useState([])
  const [filterstartdate, setfilterstartdate] = useState(moment().format("YYYY-MM-DD"))
  const [filterenddate, setfilterenddate] = useState(moment().format("YYYY-MM-DD"))
  const [filtercontributor, setfiltercontributor] = useState("0")

  function getData() {
    setLoading(true)
    var queryParams = { params: { startdate: filterstartdate, enddate: filterenddate } }
    if (localStorage.user_access !== "admin") {
      queryParams = { params: { startdate: filterstartdate, enddate: filterenddate, creator_id: localStorage.id } }
      setfiltercontributor(localStorage.id)
    }
    else {
      if (filtercontributor !== "0") {
        queryParams = { params: { startdate: filterstartdate, enddate: filterenddate, creator_id: filtercontributor } }
      }
    }
    Api.getDocument(queryParams).then(res => {
      console.log("res.data", res.data)
      let response = res.data
      setLoading(false)
      var user_access = localStorage.getItem('user_access');
      var user_id = localStorage.getItem('id');
      var data_response = response;
      var data_table = [];

      if (user_access === "kontributor") {
        data_response.forEach(element => {
          console.log("Element id ", element)
          // console.log("user id ", user_id)
          if (element.created.create_by === user_id) {
            data_table.push(element);
          }
        });

        console.log('Data res ', data_response)
        setRows(data_table);
        setData(data_table);

      }
      else {
        setRows(data_response);
        setData(data_response);
      }
    }).catch(err => console.log('Err ', err))
  }

  console.log("rows", rows)

  const getDataUsers = () => {
    Api.getUsers().then(res => {
      setusers(res.data)
    }).catch(err => console.log("error", err))
  }

  useEffect(() => {
    const fetchData = () => {
      getDataUsers()
      getData();
    }
    fetchData()
    // getDataUsers()
    // getData();

  }, []);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  var classes = useStyles();

  const handleClickOpen = () => {
    props.history.push('/app/gallery/document/form/0')
  };

  function deleteData(id) {
    axios.delete(url + "/private/document/" + id, { headers }).then(() => {
      setOpenModalDelete(false);
      getData();
    })
  }

  function handleClickOpenDelete(id, title) {
    setIdNews(id);
    setTitleNews(title);
    setOpenModalDelete(true);
  };

  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e) => {
      return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
    })
    setRows(result)
    setSearch(searchQuery)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <>
      <PageTitle title="Konten Dokumen" button="Tambah Konten" click={handleClickOpen}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.textSearch }
            }}
          />
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Box>
            Cari Berdasarkan :
            Dari Tgl <TextField type="Date" value={filterstartdate} onChange={e => setfilterstartdate(e.target.value)} /> sampai <TextField type="Date" value={filterenddate} onChange={e => setfilterenddate(e.target.value)} style={{ marginRight: 5 }} />
            <Select style={{ marginRight: 5 }} disable={localStorage.user_access === "admin" ? 'false' : 'true'} value={filtercontributor} onChange={(e) => setfiltercontributor(e.target.value)}>
              <MenuItem value="0">Pilih Kontributor</MenuItem>
              {
                users.map(item => {
                  return (
                    <MenuItem key={item} value={item._id}>{item.email}</MenuItem>
                  )
                })
              }
            </Select>
            <Button variant="contained" color="primary" onClick={() => {
              getData()
            }}>Cari</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>

          <Paper>
            {/* <Hidden smDown> */}
            {
              loading ?
                <center style={{ padding: 50 }}>
                  <CircularProgress />
                  <Typography>Sedang memuat data...</Typography>
                </center> :
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center', width: '3%' }}>No</TableCell>
                        <TableCell style={{ textAlign: 'center', width: '10%' }}>File</TableCell>
                        <TableCell style={{ textAlign: 'left', width: '10%' }}>Nama Dokumen</TableCell>
                        <TableCell style={{ textAlign: 'left', width: '40%' }}>Kategori</TableCell>
                        <TableCell style={{ textAlign: 'left', width: '10%' }}>Redaksi</TableCell>
                        <TableCell style={{ textAlign: 'center', width: '10%' }}>Status</TableCell>
                        <TableCell style={{ textAlign: 'center', width: '10%' }}>Aksi</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        rows.length > 0 ?
                          rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                              let creator_id = item.creator.length > 0 ? item.creator[0]._id : ""
                              return (
                                <TableRow key={index}>
                                  <TableCell style={{ textAlign: 'center', width: '3%' }}><Typography className={classes.txtContentTable}>{(page * rowsPerPage) + (index + 1)}</Typography></TableCell>
                                  <TableCell style={{ width: '15%' }}>
                                    <Typography style={{ textAlign: 'center', fontSize: 16 }}>{item.title}</Typography>
                                    {/* <Typography style={{fontSize:16}}>Title : {item.titleEN}</Typography> */}
                                    {/* <Typography style={{fontSize:12}}>Dibuat Tgl : {moment(item.created.create_date).format("DD-MM-YYYY HH:mm:ss")}</Typography> */}
                                  </TableCell>
                                  <TableCell style={{ textAlign: 'center', width: '25%' }}>
                                    <a href={url + "/repo/" + item.file} target="_blank"><Button variant="outlined"
                                      style={{ border: '2px solid #bf0d00', textTransform: 'none', fontSize: 10 }}>{item.file}</Button></a>
                                  </TableCell>


                                  <TableCell style={{ width: '20%' }}>
                                    <Typography >{item.name}</Typography>
                                  </TableCell>
                                  <TableCell style={{ width: '10%' }}>
                                    <Typography style={{ fontSize: 13 }}>Kontributor : {item.creator.length > 0 ? item.creator[0].email : ""}</Typography>
                                    {
                                      item?.editor ?
                                        <Typography style={{ fontSize: 13 }}><small>Editor : {item?.editor?.length > 0 ? item?.editor[0]?.email : ""} </small></Typography >
                                        : null
                                    }
                                    {
                                      item?.publisher ?
                                        <Typography style={{ fontSize: 13 }}><small>Publisher : {item?.publisher?.length > 0 ? item.publisher[0]?.email : ""}</small></Typography >
                                        : null
                                    }
                                  </TableCell>
                                  <TableCell style={{ textAlign: 'center', width: '15%' }}><Typography>{item.document_status}</Typography></TableCell>
                                  <TableCell style={{ textAlign: 'center', width: '10%' }}><Button className={classes.btnAction} component={NavLink} to={"/app/gallery/document/form/" + item._id}><Typography className={classes.txtAction}>Edit</Typography></Button>
                                    {
                                      localStorage.getItem('user_access') === "editor" || localStorage.getItem('user_access') === "kontributor" ? null :
                                        localStorage.getItem('user_access') === "redaktor" && localStorage.getItem("id") !== creator_id ? null :
                                          <Button className={classes.btnAction} onClick={() => handleClickOpenDelete(item._id, item.title)}><Typography className={classes.txtAction}>Hapus</Typography></Button>
                                    }
                                  </TableCell>
                                </TableRow>
                              )
                            }) : null
                      }
                    </TableBody>
                  </Table>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
            }


          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>Berita dengan Judul : {titleNews}</Typography>
        }
        cancel={handleClose}
        confirm={() => deleteData(idNews)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />
    </>
  );
}