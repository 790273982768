import React, { useState } from 'react'
import { 
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core'
import PageTitle from '../Components/PageTitle/PageTitle'
import axios from 'axios'
import StaticVar from '../../Config/StaticVar'
import DialogModal from '../Components/Dialog/Dialog'

const Form = (props) => {

  const url = StaticVar.URL_API;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [listAnswer, setListAnswer ] = useState([{ name: '', value: '' }])
  const [question, setQuestion] = useState("")

  const [openAlert, setOpenAlert] = useState(false)
  const [messageError, setMessageError] = useState("")

  const handleInputs = () => { 
    let newInput = {name: '', value: ''}
    setListAnswer([...listAnswer, newInput])
   }

  const handleFormChange = (index, event) => { 
    let data = [...listAnswer]
    data[index][event.target.name] = event.target.value
    setListAnswer(data)
   }

  const removeInputs = (index) => { 
    let data = [...listAnswer]
    data.splice(index, 1)
    setListAnswer(data)
   }
   
   const handleSubmit = () => { 
    let sendData = {question: question, listAnswer: listAnswer}

    console.log("Data question ", JSON.stringify(sendData))

    axios
      .post(url + "/private/survey_questions/create", sendData, { headers })
      .then(()=>{
        props.history.push('/app/survey/list')
      })
    // axios({
    //   method: 'post',
    //   url: url + '/private/survey_questions/create',
    //   data: sendData,
    //   headers: headers,
    //   onUploadProgress: function(progressEvent){
    //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //   },
    // })
    // .then(()=>{
    //   props.history.push('/app/survey/list')
    // })
    // .catch(err => {
    //   setOpenAlert(true)
    //   setMessageError(err)
    // })
   }
  
  // console.log("Question ",question)
  // console.log("List Answer ",listAnswer)
  return (
    <>
    <PageTitle title='Form Survey' />
    <DialogModal 
      open={openAlert}
      close={()=>setOpenAlert(false)}
      title="Harap periksa data isian Anda.."
      content={<Typography>{messageError}</Typography>}
      cancel={()=> setOpenAlert(false)}
      confirm={()=>setOpenAlert(false)}
      valueConfirm="Oke"
      valueCancel="Tutup"
    />
    <Box component='div'>
      <Grid container spacing={6}>
        <Grid item xs={12}>
        <TextField
          fullWidth
          id="questions"
          label="Pertanyaan"
          value={question}
          required
          multiline
          minRows={4}
          onChange={e => setQuestion(e.target.value)}
            // value={value}
            // onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
        {listAnswer.map((answer, index)=>{
            return(
              <Box key={index} component='div' sx={{ display: 'flex'}}>
                <Box width='80%' mr={2} mb={2}>
                  <TextField
                    fullWidth 
                    name='name'
                    placeholder='Pilihan jawaban'
                    onChange={event => handleFormChange(index, event)}
                    value={answer.name}
                    // sx={{ mb: 1, mr: 1 }}
                    // key={index}
                    />
                </Box>
                <Box width='80%' mr={2} mb={2}>
                  <TextField
                    fullWidth 
                    name='value'
                    placeholder='Nilai'
                    onChange={event => handleFormChange(index, event)}
                    value={answer.value}
                    // sx={{ mb: 1, mr: 1 }}
                    // key={index}
                    />
                </Box>
                {index == 0 ?
                  null 
                  :
                  <Box>
                    <Button variant='outlined' color='error' onClick={removeInputs}>Hapus</Button>
                  </Box>
                }
            </Box>
            )
          })}
        </Grid>
        <Grid item xs={6}>
          <Button variant='outlined' onClick={handleInputs}>Tambah Pilihan..</Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Button variant='contained' onClick={() => props.history.push('/app/survey/list')}>Batal</Button>
            </Grid>
            <Grid item xs={1}>
              <Button onClick={handleSubmit} variant='contained' color='primary'>Simpan</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </>
  )
}

export default Form