import React, { useEffect, useState } from 'react'
import { 
  Box, 
  Table, 
  TableCell, 
  TableContainer, 
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Button, 
  Grid,
  TextField,
  Paper,
  TablePagination,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Event as DateTimeIcon } from '@material-ui/icons';

import DialogModal from '../Components/Dialog/Dialog'
import PageTitle from '../Components/PageTitle/PageTitle'
import StaticVar from '../../Config/StaticVar'
import axios from 'axios'

const Events = (props) => {

  const url = StaticVar.URL_API;
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  }

  // State
  // State dialog
  const [openDialogAdd, setOpenDialogAdd] = useState(false)
  const [openDialogEdit, setOpenDialogEdit] = useState(false)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)

  // const [selectedDate, handleDateChange] = useState(new Date("2019-01-01T18:54"))

  const [data, setData] = useState([])

  // state form data
  const [id, setId] = useState("")
  const [eventName, setEventName] = useState("")
  const [eventStartDate, handleEventStartDate] = useState(new Date())
  const [eventEndDate, handleEventEndDate] = useState(new Date())
  const [eventStartEditDate, setEventStartEditDate] = useState(null)
  const [eventEndEditDate, setEventEndEditDate] = useState(null)
  const [descriptions, setDescriptions] = useState("")


  useEffect(()=>{
    getData();
    return () => {
      getData();
    };
  }, [])

  // function
  const handleCloseDialog = () => { 
    setOpenDialogAdd(false)
    setOpenDialogEdit(false)
    setOpenDialogDelete(false)
  }

  const handleClickOpenAdd = () => {
    setId("");
    setEventName("")
    handleEventStartDate(new Date())
    handleEventEndDate(new Date())
    setOpenDialogAdd(true)
  }

  function handleClickOpenEdit(id, name, startDate, endDate, description) {
    setId(id);
    setEventName(name)
    setEventStartEditDate(new Date(startDate))
    setEventEndEditDate(new Date(endDate))
    setDescriptions(description)
    setOpenDialogEdit(true);
  };

  //API

  let sendData = {
    name: eventName,
    start: eventStartDate.toISOString(),
    end: eventEndDate.toISOString(),
    description: descriptions,
  }

  const getData = async () => { 
    const res = await axios.get(`${url}/private/event`, { headers })
    const response = await res.data
    setRows(response)
    setData(response)

  }
  
  const postData = () => { 
    axios.post(`${url}/private/event/create`, sendData, { headers })
      .then((res)=>{
        console.log("Send Data ", res.data)
        setOpenDialogAdd(false)
        getData()
      })
  }

  const editData = (id) => { 
    let editdData = {
      name: eventName,
      start: eventStartEditDate.toISOString(),
      end: eventEndEditDate.toISOString(),
      description: descriptions,
    }

    axios.put(`${url}/private/event/${id}`, editdData, { headers })
      .then(()=>{
        setOpenDialogEdit(false)
        getData()
      })
  }

  const deleteData = (id) => { 
    axios.delete(`${url}/private/event/${id}`, { headers })
      .then(()=>{
        setOpenDialogEdit(false)
        getData()
      })
   }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  console.log("Date ", new Date().toLocaleDateString())
  return (
    <>
    <PageTitle title='Kegiatan' button='Tambah Kegiatan' click={handleClickOpenAdd} />
    <Box>
      <Paper sx={{ px: 1 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Kegiatan</TableCell>
                <TableCell>Deskripsi</TableCell>
                <TableCell>Waktu Kegiatan</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <Typography>{page * rowsPerPage + (index + 1)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography> {item.name} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.description}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Dari : {new Date(item.start).toLocaleDateString() +" " + new Date(item.start).toLocaleTimeString()}</Typography>
                    <Typography>Sampai: {new Date(item.end).toLocaleDateString() +" " + new Date(item.end).toLocaleTimeString()}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleClickOpenEdit(
                        item._id,
                        item.name,
                        item.start,
                        item.end,
                        item.description,
                      )}
                    >
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination 
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>

    {/* <DialogModal /> */}
    <DialogModal 
      open={openDialogAdd}
      close={handleCloseDialog}
      cancel={handleCloseDialog}
      confirm={()=> postData()}
      valueCancel="Batalkan"
      title="Tambah Kegiatan"
      valueConfirm="Tambah"
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              label='Kegiatan'
              name='eventName'
              value={eventName}
              onChange={(e)=> setEventName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                autoOk
                ampm={false}
                value={eventStartDate}
                onChange={handleEventStartDate}
                label="Mulai Tanggal"
                onError={console.log}
                minDate={new Date("2018-01-01T00:00")}
                format="yyyy/MM/dd hh:mm:ss"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateTimeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={eventEndDate}
                onChange={handleEventEndDate}
                label="Mulai Tanggal"
                onError={console.log}
                minDate={new Date(eventStartDate)}
                format="yyyy/MM/dd hh:mm:ss"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateTimeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              label="Deskripsi"
              name='description'
              value={descriptions}
              onChange={(e)=> setDescriptions(e.target.value)}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      }
    />

    {/* Dialog Edit */}
    <DialogModal 
      open={openDialogEdit}
      close={handleCloseDialog}
      title="Edit Kegiatan"
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              label='Kegiatan'
              name='eventName'
              value={eventName}
              onChange={(e)=> setEventName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                autoOk
                ampm={false}
                // defaultValue={eventStartEditDate}
                value={eventStartEditDate}
                onChange={setEventStartEditDate}
                label="Mulai Tanggal"
                onError={console.log}
                minDate={new Date("2018-01-01T00:00")}
                format="yyyy/MM/dd hh:mm:ss"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateTimeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={eventEndEditDate}
                // defaultValue={eventEndEditDate}
                onChange={setEventEndEditDate}
                label="Mulai Tanggal"
                onError={console.log}
                minDate={new Date(eventStartEditDate)}
                format="yyyy/MM/dd hh:mm:ss"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateTimeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              label="Deskripsi"
              name='description'
              value={descriptions}
              onChange={(e)=> setDescriptions(e.target.value)}
              multiline
              minRows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => editData(id)} style={{ border: '2px solid #78f056', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Simpan</Typography>
            </Button>
            <Button onClick={handleCloseDialog} style={{ border: '2px solid #6f9ae8', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Batalkan</Typography>
            </Button>
            <Button onClick={() => deleteData(id)} style={{ border: '2px solid #de1200', marginRight: 10 }}>
              <Typography style={{ textTransform: 'none' }}>Hapus</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    />

    {/* Alert Dialog */}

    </>
  )
}

export default Events