import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";

// pages
import Events from "../Events/Events";
import Dashboard from "../Dashboard/Dashboard";
import Category from "../News/Category";
import NewsContent from "../News/Content";
import EditorContent from "../News/EditorContent";
import AddContent from "../News/AddContent";
import ArticlesCategory from "../Articles/Category";
import ArticlesContent from "../Articles/Content";
import EditorArticlesContent from "../Articles/EditorContent";
import AddArticlesContent from "../Articles/AddContent";
import VideoNewsContent from "../VideoNews/VideoContent";
import Document from "../Document/Document";
import CategoryDocument from "../Document/Category";
import FormDocument from "../Document/FormDocument";
import Organization from "../Organization/Organization";
import FormOrganization from "../Organization/FormOrganization";
import GalleryPhoto from "../Gallery/Photos";
import AddPhoto from "../Gallery/AddPhotos";
import AddVideo from "../Gallery/AddVideos";
import PhotosCategories from "../Gallery/PhotosCategories";
import VideosCategories from "../Gallery/VideosCategories";
import GalleryVideo from "../Gallery/Videos";
import Pages from "../Pages/Pages";
import Sitemap from "../Sitemap/Sitemap";
import Highlight from "../Highlight/Highlight";
import Users from "../Users/Users";
import Access from "../Users/Access";
import VideoEditorContent from "../VideoNews/VideoEditorContent";
import Faq from "../Faq/ListFaq";
import AddFaq from "../Faq/AddFaq";
import EditFaq from "../Faq/EditFaq";
import VideoAddContent from "../VideoNews/VideoAddContent";
import Sponsor from "../Sponsor/Sponsor";
import Games from "../Games/index";
import Activity from "../Report/Activity";
import Funds from "../Report/Funds";
import Materi from "../Materi/Materi";
// context
import { useLayoutState } from "../../Context/LayoutContext";

import AddPage from "../Pages/AddPage";
import EditPage from "../Pages/EditPage";
import Popup from "../Popup/Popup";
import SurveyResult from "../Survey/SurveyResult";
import Form from "../Survey/Form";
import ListSurvey from "../Survey/ListSurvey";
import EditorSurvey from "../Survey/EditorSurvey";
import EditorOrganization from "../Organization/EditorOrganization";
import AddActivity from "../Report/AddActivity";
import AddFunds from "../Report/AddFunds";
import EditActivity from "../Report/EditActivity";
import EditFunds from "../Report/EditFunds";
import Categories from "../AllCategories/Categories";
import Dataset from "../Dataset/Dataset";
import AddDataset from "../Dataset/AddDataset";
import DatasetCategories from "../Dataset/DatasetCategories";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/app/dashboard" component={Dashboard} />
            <Route exact path="/app/news/content" component={NewsContent} />
            <Route exact path="/app/news/category" component={Category} />
            <Route
              exact
              path="/app/news/content/form-content/"
              component={AddContent}
            />
            <Route
              exact
              path="/app/news/content/editor-content/:_id"
              component={EditorContent}
            />

            <Route
              exact
              path="/app/articles/content"
              component={ArticlesContent}
            />
            <Route
              exact
              path="/app/articles/category"
              component={ArticlesCategory}
            />
            <Route
              exact
              path="/app/articles/content/form-content/"
              component={AddArticlesContent}
            />
            <Route
              exact
              path="/app/articles/content/editor-content/:_id"
              component={EditorArticlesContent}
            />

            <Route
              exact
              path="/app/videonews/content"
              component={VideoNewsContent}
            />
            <Route
              exact
              path="/app/videonews/content/form-content/"
              component={VideoAddContent}
            />
            <Route
              exact
              path="/app/videonews/content/editor-content/:_id"
              component={VideoEditorContent}
            />


            <Route exact path="/app/organization" component={Organization} />
            <Route
              exact
              path="/app/organization/form/:_id"
              component={FormOrganization}
            />
            <Route
              exact
              path="/app/organization/edit/:_id"
              component={EditorOrganization}
            />

            <Route exact path="/app/highlight" component={Highlight} />
            <Route exact path="/app/gallery/photos" component={GalleryPhoto} />
            <Route
              exact
              path="/app/gallery/photos/add-photos/:category"
              component={AddPhoto}
            />
            <Route
              exact
              path="/app/gallery/videos/add-videos"
              component={AddVideo}
            />
            <Route exact path="/app/gallery/videos" component={GalleryVideo} />
            <Route
              exact
              path="/app/gallery/categories-photos"
              component={PhotosCategories}
            />
            <Route
              exact
              path="/app/gallery/categories-videos"
              component={VideosCategories}
            />

            <Route
              exact
              path="/app/gallery/categories-document"
              component={CategoryDocument}
            />
            <Route exact path="/app/gallery/document" component={Document} />
            <Route
              exact
              path="/app/gallery/document/form/:_id"
              component={FormDocument}
            />

            <Route exact path="/app/pages" component={Pages} />
            <Route exact path="/app/pages/new_page" component={AddPage} />
            <Route
              exact
              path="/app/pages/edit_page/:_id"
              component={EditPage}
            />
            <Route exact path="/app/sitemap" component={Sitemap} />
            <Route exact path="/app/sponsor" component={Sponsor} />
            <Route exact path="/app/users/list" component={Users} />
            <Route exact path="/app/users/access" component={Access} />

            <Route exact path="/app/faq" component={Faq} />
            <Route exact path="/app/faq/form" component={AddFaq} />
            <Route exact path="/app/faq/edit/:_id" component={EditFaq} />

            <Route exact path="/app/dataset/list" component={Dataset} />
            <Route exact path="/app/dataset/form" component={AddDataset} />
            <Route exact path="/app/dataset/category" component={DatasetCategories} />

            {/* report  & materi */}
            <Route exact path="/app/gallery/materi" component={Materi} />
            <Route exact path="/app/report/activity" component={Activity} />
            <Route
              exact
              path="/app/report/activity/form"
              component={AddActivity}
            />
            <Route
              exact
              path="/app/report/activity/form/:_id"
              component={EditActivity}
            />
            <Route exact path="/app/report/funds" component={Funds} />
            <Route
              exact
              path="/app/report/funds/form"
              component={AddFunds}
            />
            <Route
              exact
              path="/app/report/funds/form/:_id"
              component={EditFunds}
            />

            <Route exact path="/app/events" component={Events} />
            <Route exact path="/app/popup" component={Popup} />
            <Route exact path="/app/survey/result" component={SurveyResult} />
            <Route exact path="/app/survey/list" component={ListSurvey} />
            <Route exact path="/app/survey/form" component={Form} />
            <Route
              exact
              path="/app/survey/edit-form/:_id"
              component={EditorSurvey}
            />

            {/* <Route exact path="/app/elearning/category" component={Categories} /> */}
            {/* <Route exact path="/app/games/:type/:id" component={Games} /> */}
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
