import axios from 'axios';
import React, { useEffect, useState } from 'react'
import PageTitle from '../Components/PageTitle/PageTitle'
import StaticVar from '../../Config/StaticVar';
import { 
  Grid, 
  InputAdornment, 
  Table, 
  TableCell, 
  TableRow,
  TableBody,
  TableHead,
  TextField,
  Button,
  Typography,
  Paper
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DialogModal from '../Components/Dialog/Dialog';

const SurveyResult = () => {

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = useState([])
  const [answers, setAnswers] = useState([])
  const [open, setOpen] = useState(false)

  const getData = async () => { 
    let res = await axios.get(url + '/private/survey', {headers})
    let response = await res.data
    setRows(response)
    setData(response)
  }

  const viewAnswer = (answer) => { 
    setAnswers(answer)
    setOpen(true)
  }

  const handleClose = () => { 
    setOpen(false)
   }

  useEffect(()=>{
    getData()
  }, [])
  
  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("")
   
  const handleSearch = (event) => {
   var searchQuery = event.target.value.toString().toLowerCase();
   const result = data.filter((e)=>{
     return e.name.toString().toLowerCase().indexOf(searchQuery) !== -1;
   })
   setRows(result)    
   setSearch(searchQuery)
 }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  console.log("Hasil survey ", rows);
  return (
   <>
   <PageTitle 
    title='Hasil Survey' 
    filter={
      <TextField
        variant="outlined"
        onChange={handleSearch}
        value={search}
        // className={classes.searchField}
        sx={{
          marginRight:20,
          background: 'white',
          width: '100%',
          fontWeight:400,
          borderRadius: { xs: 10, md: 5},
          borderColor: 'white',
          boxShadow: '2.5px 2.5px 2.5px 1.5px rgba(0, 0, 0, 0.1)',
        }}
        placeholder="Cari.."
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        // classes : {input : classes.textSearch}
        }}
      />
    }
    />
   <Grid container>
    <Grid item xs={12}>
      <Paper elevation={1} p={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Nama</TableCell>
              <TableCell>Tanggal Survey</TableCell>
              <TableCell>Komentar</TableCell>
              <TableCell>Jawaban</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return(
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>
                        {(page * rowsPerPage)+(index + 1)}
                      </Typography>
                      </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{new Date(item.created).toLocaleDateString()}</TableCell>
                    <TableCell>{item.comment}</TableCell>
                    <TableCell>
                      <Button variant='contained' color='primary' onClick={()=>viewAnswer(item.surveyResult)}>Lihat</Button>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </Paper>
    </Grid>
   </Grid>

   <DialogModal 
    open={open}
    close={handleClose}
    cancel={handleClose}
    valueCancel="Tutup"
    title="Jawaban"
    content={
      <Grid container>
        <Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Pertanyaan</TableCell>
                <TableCell>Jawaban (Nilai)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                answers.map((item, index)=>(
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{item.question}</TableCell>
                    <TableCell>{item.answer}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Table>
      </Grid>
    }
   />
   </>
  )
}

export default SurveyResult