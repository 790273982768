export const komponen = [
  "Honorarium",
  "Operasional",
  "Produksi Alat",
  "Produksi Non Alat",
  "Pengelolaan Program",
];

export const aktivitas = [
  "Sosialisasi dan Pelatihan Sistem Informasi Atlet Karateka",
  "Pembuatan Dataset Video Analytics Pose Atlet Karate",
  "Pengembangan Program Video Analytics berbasis AI",
  "Pengembangan Aplikasi Mobile Karateka untuk e-Learning",
  "Pelaporan",
];

export const bulan = [
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const sumber_dana = [
  "DIKTI",
  "Perguruan Tinggi",
  "Mitra In-Cash",
  "Mitra In-Kind",
];
