import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TablePagination,
  LinearProgress,
  Box
} from "@material-ui/core";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";
import StaticVar from "../../Config/StaticVar"

export default function AddPhotos(props) {
  var classes = useStyles();

  const [files, setFiles] = useState([{ title: null }, { category: null }]);
  const [onError, setOnError] = useState(false)

  const url = StaticVar.URL_API;//"http://localhost:301"//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const category = props.match.params.category;

  function handleInput(type, i, event) {
    const titlePhotos = [...files];
    const captionPhotos = [...files];
    const categoryPhotos = [...files];
    if(type === "title"){
      titlePhotos[i].title = event.target.value;
    }
    else{
      captionPhotos[i].caption = event.target.value;
    }
    categoryPhotos[i].category = category;
    setFiles(titlePhotos);
    setFiles(categoryPhotos);
    console.log('data target : ', files)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 500000,

    onDrop: acceptedFiles => {
      setOnError(false)
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      );

      console.log('value : ', acceptedFiles)

    },
    onDropRejected: () =>{
      setOnError(true)
    }
  });

  // console.log("File reject ", fileRejections)

  // const FileReject = fileRejections?.map(({ file, errors }) =>{
  //   return (
  //     <Box key={file.path}>
  //       <Typography>Ukuran file {file.size / 1000} KB</Typography>
  //       <Typography>Maksimum ukuran file adalah 500 KB</Typography>
  //     </Box>
  //   )
  // })

  async function postData() {
    
    var options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 3000,
      useWebWorker: true
    }

    files.map(async (file,index) => {

      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log('compressedFile size ', compressedFile.size / 1024 / 1024); // smaller than maxSizeM
        console.log('data file', file.caption)
        const formData = new FormData();
        formData.append('title', file.title);
        formData.append('picture', compressedFile);
        formData.append('category', file.category);
        formData.append('caption', file.caption);
        formData.append('create_by', localStorage.id)
        formData.append('photo_status', "Foto Baru");
        axios({
          method: 'post',
          url: url + "/private/photos/createMany",
          data: formData, 
          headers:headers,
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log('test progress upload',percentCompleted);
            console.log('test progress loaded',progressEvent.loaded);
          },
        })
        .then(() => {
          props.history.push('/app/gallery/photos');
        })

      } catch (error) {
        console.log(error);
      }

    });

  }

  const thumbs = files.map((file, index) => {

    if (file.title !== null && file.category !== null) {
      return (
        <Grid container key={index} spacing={3} sx={{ my: 3}}>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formContainer}
            // value={}
            onChange={e => handleInput("title", index, e)}
            id="standard-full-width"
            label="Judul"
            style={{ width:'100%' }}
            placeholder="Judul Foto atau Gambar"
            fullWidth
            margin="normal"
          />
          <TextField
            className={classes.formContainer}
            onChange={e => handleInput("caption",index, e) }
            id="standard-full-width"
            label="Keterangan Foto atau Gambar"
            style={{ width:'100%', height:50 }}
            placeholder="Keterangan Foto atau Gambar"
            fullWidth
            margin="normal"
            multiline={3}
          />
        </Grid>
        <Grid item xs={12} md={6} >
          <center style={{margin:10}}>
            <img
              src={file.preview}
              style={{width:400}}
            />
          </center>
        </Grid>
        </Grid>
      )
    }
    else {
      return (
        null
      )
    }
  }
  );

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <PageTitle title="Galeri Foto" />
      <Grid container spacing={4} style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
        <div {...getRootProps({ className: 'dropzone' })} style={{ width: '100%', height: 100, border: '2px solid #e0e0e0', borderRadius: 5 }}>
          <input {...getInputProps()} />
          <Typography style={{ marginTop: 30, textAlign: 'center' }}>Drag 'n' drop Foto atau Gambar dibawah ini atau Klik beberapa Foto</Typography>
          {onError ? 
            <Typography style={{ marginTop: 16, textAlign: 'center', color: 'red', fontSize: 12}}>Maksimum ukuran file 500 KB</Typography> 
            : 
            null}
        </div>
        
        <Grid container spacing={10}>
          <Grid item md="12" lg="12" sm="12" xs="12">
            {thumbs}
          </Grid>
          <Grid item md="12" lg="12" sm="12" xs="12">
            <Button
                style={{
                  width: 120,
                  border: "2px solid gray",
                  marginBottom: 10,
                  marginRight: 10,
                }}
                onClick={() => {
                  props.history.push("/app/gallery/photos");
                }}
              >
                Batal
              </Button>
            <Button className={classes.btnActionAdd} onClick={() => postData()}>
              <Typography className={classes.txtAction}>Tambah</Typography>
            </Button>
          </Grid>
        </Grid>
        
      </Grid>
    </>
  );
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const thumbsContainer = {
  marginTop: 16,
  width: "100%"
};