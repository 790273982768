import React from "react";
import {
  Grid, 
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
} from "@material-ui/core";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";


export default function Access() {
  var classes = useStyles();
  
  var datatable = [
    {
      id: 1,
      name: "Berita Olahraga",
      contributor: [
        {name:"Syahri Ramadhan"},{name:"Syahri Ramadhan"},{name:"Syahri Ramadhan"}
      ],
      editor:[
        {name:"Muhammad Faiz"},{name:"Muhammad Faiz"},{name:"Muhammad Faiz"}
      ],
      redaktor:[
        {name:"Muhammad Faiz"}
      ]
    },
    {
      id: 2,
      name: "Berita Olahraga",
      contributor: [
        {name:"Syahri Ramadhan"},{name:"Syahri Ramadhan"},{name:"Syahri Ramadhan"}
      ],
      editor:[
        {name:"Muhammad Faiz"},{name:"Muhammad Faiz"},{name:"Muhammad Faiz"}
      ],
      redaktor:[
        {name:"Muhammad Faiz"}
      ]
    }
  ];

  var titleTable = [
    {name:'No'},{name:'Kategori'},{name:'Kontributor'},{name:'Editor'},{name:'Redaktor'},{name:'Action'}
  ]
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PageTitle title="Users Access" click={handleClickOpen} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
              <TableRow>
                <TableCell><Typography className={classes.txtContentTable}>No</Typography></TableCell>
                <TableCell><Typography className={classes.txtContentTable}>User Akses</Typography></TableCell>
                <TableCell><Typography className={classes.txtContentTable}>Hak Akses</Typography></TableCell>
                <TableCell><Typography className={classes.txtContentTable}>Aksi</Typography></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                
                {/* {datatable.map((item,index) => (
                  <TableRow key={item.id}>
                    <TableCell><Typography className={classes.txtContentTable}>{index+1}</Typography></TableCell>
                    <TableCell><Typography className={classes.txtContentTable}>{item.name}</Typography></TableCell>
                    <TableCell>
                      {
                        item.contributor.map(itemKontributor=>(
                          <Typography className={classes.txtContentTable}>{itemKontributor.name}</Typography>
                        ))
                      }
                    </TableCell>
                    <TableCell>
                      {
                        item.editor.map(itemeditor=>(
                          <Typography className={classes.txtContentTable}>{itemeditor.name}</Typography>
                        ))
                      }
                    </TableCell>
                    <TableCell>
                      <Button className={classes.btnAction}><Typography className={classes.txtAction}>Edit</Typography></Button>
                      <Button className={classes.btnAction}><Typography className={classes.txtAction}>Delete</Typography></Button>
                    </TableCell>
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
