import React, { useEffect, useState } from 'react'
import { 
  Grid, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  Typography,
  Button,
  TablePagination, 
  TableContainer
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import PageTitle from '../Components/PageTitle/PageTitle'
import axios from 'axios'
import StaticVar from '../../Config/StaticVar'
import DialogModal from '../Components/Dialog/Dialog'


const ListSurvey = (props) => {

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  
  const [rowsData, setRowsData] = useState([])
  const [id, setId] = useState("")
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleAddSurvey = () => { 
    props.history.push('/app/survey/form')
   }
   
  const getData = async () => { 
    let res = await axios.get(url + '/private/survey_questions', { headers})
    let response = await res.data
    setRowsData(response)
    setRows(response)
   }

  const handleDialogDelete = (id) => { 
    setOpenDeleteDialog(true)
    setId(id)
   }

  const deleteData = () => { 
    axios
      .delete(url + '/private/survey_questions/' + id, { headers})
      .then(()=>{
        console.log('Success delete ', id)
        // props.history.push('/app/survey/list')
        setOpenDeleteDialog(false)
        getData()
      })
   }

  useEffect(()=>{
    getData()
  },[])


  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
    <PageTitle title='Form Survey' button='Tambah Form Survey' click={handleAddSurvey} />

    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Pertanyaan</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell>{page * rowsPerPage + (index + 1)}</TableCell>
                      <TableCell>{item.question}</TableCell>
                      <TableCell>
                        <Button component={NavLink} to={"/app/survey/edit-form/"+ item._id} variant='outlined' >Edit</Button>
                        <Button variant='outlined' onClick={() => handleDialogDelete(item._id)} >Hapus</Button>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination 
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>

    <DialogModal 
      open={openDeleteDialog}
      title="Hapus Pertanyaan?"
      valueConfirm="Ya"
      valueCancel="Batalkan"
      cancel={() => setOpenDeleteDialog(false)}
      confirm={() => deleteData()}
    />
    </>
  )
}

export default ListSurvey