import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  AccountTree as AccountTreeIcon,
  PhotoAlbumOutlined as PhotoAlbumOutlinedIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  LiveHelp as LiveHelpIcon,
  AnnouncementOutlined as AnnouncementOutlinedIcon,
  Pages as PagesIcon,
  BurstMode as BurstModeIcon,
  AccountTreeRounded as OrganizationIcon,
  AnnouncementRounded as AnnouncementRoundedIcon,
  BrandingWatermarkOutlined as BrandingWatermarkOutlinedIcon,
  Sports as SportsIcon,
  PanoramaHorizontal as PopupIcon,
  EventNote as EventsIcon,
  Person as UserIcon,
  Poll as SurveyIcon,
  Assessment as ReportIcon,
  Receipt as MateriIcon,
  Storage as DatasetIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../Context/LayoutContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news/content",
    icon: <AnnouncementOutlinedIcon />,
    children: [
      { label: "Kategori ", link: "/app/news/category" },
      { label: "Isi Berita", link: "/app/news/content" },
    ],
  },
  {
    id: 2,
    label: "Berita Highlight",
    link: "/app/highlight",
    icon: <BurstModeIcon />,
  },
  {
    id: 3,
    label: "Artikel",
    link: "/app/articles/content",
    icon: <AnnouncementRoundedIcon />,
    children: [
      { label: "Kategori", link: "/app/articles/category" },
      { label: "Isi Artikel", link: "/app/articles/content" },
    ],
  },
  {
    id: 4,
    label: "Galeri",
    link: "/app/gallery/photos",
    icon: <PhotoAlbumOutlinedIcon />,
    children: [
      { label: "Kategori Foto", link: "/app/gallery/categories-photos" },
      { label: "Galeri Foto", link: "/app/gallery/photos" },
      { label: "Kategori Video", link: "/app/gallery/categories-videos" },
      { label: "Galeri Video", link: "/app/gallery/videos" },
      { label: "Kategori Materi", link: "/app/gallery/categories-document" },
      { label: "Materi", link: "/app/gallery/document" },
    ],
  },
  {
    id: 5,
    label: 'Dataset',
    link: 'app/dataset',
    icon: '',
    children: [
      { label: 'Kategori Dataset', link: '/app/dataset/category' },
      { label: 'Dataset', link: '/app/dataset/list' },
    ]
  },
  // {
  //   id: 5,
  //   label: "E-learning",
  //   link: "/app/elearning",
  //   icon: <ReportIcon />,
  //   children: [
  //     { label: "Kategori", link: "/app/elearning/category" },
  //     // { label: "Laporan Keuangan ", link: "/app/report/funds" },
  //   ],
  // },
  {
    id: 6,
    label: "Laporan",
    link: "/app/report",
    icon: <ReportIcon />,
    children: [
      { label: "Laporan Kegiatan", link: "/app/report/activity" },
      { label: "Laporan Keuangan ", link: "/app/report/funds" },
    ],
  },
  {
    id: 7,
    label: "Laman",
    link: "/app/pages",
    icon: <PagesIcon />,
  },
  {
    id: 8,
    label: "Peta Situs",
    link: "/app/sitemap",
    icon: <AccountTreeIcon />,
  },
  // {
  //   id: 8,
  //   label: "Dokumen",
  //   link: "/app/document",
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   id: 9,
  //   label: "Popup",
  //   link: "/app/popup",
  //   icon: <PopupIcon />,
  // },
  // {
  //   id: 10,
  //   label: "Organisasi",
  //   link: "/app/organization",
  //   icon: <GroupIcon />,
  // },
  // {
  //   id: 11,
  //   label: "Kegiatan",
  //   link: "/app/events",
  //   icon: <EventsIcon />,
  // },
  // {
  //   id: 12,
  //   label: "Survey",
  //   link: "/app/survey/list",
  //   icon: <SurveyIcon />,
  //   children: [
  //     {label: 'Form Survey', link: '/app/survey/list'},
  //     {label: 'Hasil Survey', link: '/app/survey/result'},
  //   ]
  // },
  {
    id: 13,
    label: "FAQ",
    link: "/app/faq",
    icon: <LiveHelpIcon />,
  },
  {
    id: 14,
    label: "User",
    link: "/app/users/list",
    icon: <UserIcon />,
    // children: [
    //   { label: "User", link: "/app/users/list" },
    //   { label: "User History", link: "/app/users/log" },
    // ],
  },
];

const structure_contributor = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news/content",
    icon: <AnnouncementOutlinedIcon />,
  },
  // {
  //   id: 2,
  //   label: "Berita Video",
  //   link: "/app/videonews/content",
  //   icon: <AnnouncementOutlinedIcon />
  // },
  {
    id: 3,
    label: "Artikel",
    link: "/app/articles/content",
    icon: <AnnouncementRoundedIcon />,
  },
  {
    id: 4,
    label: "Foto",
    link: "/app/gallery/photos",
    icon: <PhotoAlbumOutlinedIcon />,
  },
  {
    id: 5,
    label: "Video",
    link: "/app/gallery/videos",
    icon: <PhotoAlbumOutlinedIcon />,
  },
  {
    id: 6,
    label: "Materi",
    link: "/app/gallery/document",
    icon: <MateriIcon />,
  },
  {
    id: 7,
    label: "Dataset",
    link: "/app/dataset/list",
    icon: <DatasetIcon />,
  },
  // { id: 5,
  //   label: "Pertandingan",
  //   link: "/app/users/list",
  //   icon:<SportsIcon/>,
  //   children: [
  //     { label: "Jadwal dan Hasil", link: "/app/games/Schedules/Calendar" },
  //     { label: "Medali", link: "/app/games/Medals/0" },
  //     { label: "Disiplin Olahraga", link: "/app/games/Events/0" },
  //     { label: "Kontingen", link: "/app/games/Contingents/0" },
  //     { label: "Venue", link: "/app/games/Venues/0" },
  //   ],
  // },
];

const structure_redaktur = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news",
    icon: <AnnouncementOutlinedIcon />,
    children: [
      { label: "Kategori Berita", link: "/app/news/category" },
      { label: "Isi Berita", link: "/app/news/content" },
    ],
  },
  // {
  //   id: 2,
  //   label: "Berita Video",
  //   link: "/app/videonews/content",
  //   icon: <AnnouncementOutlinedIcon />
  // },
  {
    id: 3,
    label: "Galeri",
    link: "/app/gallery",
    icon: <PhotoAlbumOutlinedIcon />,
    children: [
      { label: "Galeri Foto", link: "/app/gallery/photos" },
      { label: "Kategori Foto", link: "/app/gallery/categories-photos" },
      { label: "Galeri Video", link: "/app/gallery/videos" },
      { label: "Kategori Video", link: "/app/gallery/categories-videos" },
    ],
  },
  // { id: 4,
  //   label: "Pertandingan",
  //   link: "/app/users/list",
  //   icon:<SportsIcon/>,
  //   children: [
  //     { label: "Jadwal dan Hasil", link: "/app/games/Schedules/Calendar" },
  //     { label: "Medali", link: "/app/games/Medals/0" },
  //     { label: "Disiplin Olahraga", link: "/app/games/Events/0" },
  //     { label: "Kontingen", link: "/app/games/Contingents/0" },
  //     { label: "Venue", link: "/app/games/Venues/0" },
  //   ],
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [datastructure, setdatastructure] = useState([]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    var user_access = localStorage.getItem("user_access");
    console.log("user_access", user_access);
    setdatastructure(structure);
    // if(user_access ==="redaktor" || user_access === "publisher"){
    //   setdatastructure(structure_redaktur);
    // }
    if (
      user_access === "editor" ||
      user_access === "kontributor" ||
      user_access === "redaktor" ||
      user_access === "publisher"
    ) {
      setdatastructure(structure_contributor);
    }

    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {datastructure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
        {/* {structure_redaktur.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))} */}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
