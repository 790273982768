import React, { useState,useEffect,useRef,useCallback } from "react";
import {
  Grid, 
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TablePagination,
  Divider
} from "@material-ui/core";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";

import _ from "lodash";

import { NavLink } from "react-router-dom"
import StaticVar from "../../Config/StaticVar"

export default function GalleryPhotos(props) {
  var classes = useStyles();

  const userAccess = localStorage.getItem("user_access")

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  var [idPhotos, setIdPhotos] = useState("");
  var [titlePhotos, setTitlePhotos] = useState("");
  var [captionPhotos, setcaptionPhotos] = useState("");

  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  
  var [categoryPhotos, setCategoryPhotos] = useState("");

  var [imgPreview, setImgPreview ] = useState(NoImg);
  // var [imgEditPreview, setImgEditPreview ] = useState();
  var [img, setImg ] = useState("");
  var [isImgValid, setIsImgValid ] = useState("");
  var [imgErrorMsg, setImgErrorMsg ] = useState("");

  var [index, setIndex] = useState(0);
  var [openAlert,setopenAlert] = useState(false);
  var [messageError,setmessageError] = useState("");

  var titleTable = [
    {name:'No'},{name:'Foto'},{name:'Judul'},{name:'Keterangan'},{name:'Kategori'},{name:'Status'},{name:'Action'}
  ]

  useEffect(() => {
    getData();
    getDataCategoryPhotos();
    // return () => {
    //   getData();
    //   getDataCategoryPhotos();
    // }
  }, []);

  const url = StaticVar.URL_API;// "http://localhost:301";//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const getDataCategoryPhotos = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if(user_access !== "admin"){
        var status = "contributor";
        if(user_access === "editor"){
            status = "editor"
        }
        if(user_access === "redaktor"){
            status = "redactor"
        }

        let res = await axios.get(url+"/private/photos_categories/"+status+"/"+user_id,{headers});
        let response = await res.data
        setDataCategory(response)
    }
    else{
        let res = await axios.get(url+"/private/photos_categories",{headers});
        let response = await res.data
        setDataCategory(response)
    }
  }
 
  const getData = async () => {
    let res = await axios.get(url+"/private/photos",{headers});
    let response = await res.data
    
    console.log('user_access', localStorage.getItem('user_access'))
    var user_access = localStorage.getItem('user_access');
    var user_id = localStorage.getItem('id');
    var data_response = response;
    // console.log('data ',JSON.stringify(data_response))
    var data_table = [];

    console.log('getData user acc ', user_access)

    if(user_access === "kontributor"){
      data_response.forEach(element => {
        if(element.created.create_by === user_id){
          data_table.push(element);
        }
      });
      console.log('dataTable',JSON.stringify(data_table))
      
      setData(data_table);
    }
    else if(user_access === "editor"){
      axios.get(url+"/private/photos_categories/editor/"+user_id,{headers}).
      then((res)=>{
        console.log('category', res.data);
        var dataCategory = res.data;
        data_response.forEach(element =>{
          dataCategory.forEach(elementCategory =>{
            if(element.category === elementCategory._id){
              data_table.push(element);
            }
          })
        })
        // console.log('dataTable',JSON.stringify(data_table))
        
        setData(data_table);
      })
    }
    else{
      data_table = data_response;
      // console.log('dataTable',data_table)
      
      setData(data_table);
    }
  }


  function handleClickOpenEdit(id,title,caption,category,picture){
    setIdPhotos(id);
    setTitlePhotos(title);
    setCategoryPhotos(category);
    setcaptionPhotos(caption)
    setImgPreview(url+"/repo/"+picture);
    setOpenModalEdit(true);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };
  
  const imgRef = useRef(null);

  function handleInputFile(){
      imgRef.current.click()
  }

  function handleImage(e){
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true)
      setImgErrorMsg("Format File tidak sesuai")
      return;
    }

    reader.onloadend = () => {
      setIsImgValid(false);
      setImgErrorMsg("");
      setImg(file);
      setImgPreview(reader.result)
    };

    console.log('value file :',file);

    reader.readAsDataURL(file);
  }

  function handleInput(type, e){
    if(type === "title"){
      setTitlePhotos([e.target.name]= e.target.value)
    }
    else{
      setcaptionPhotos([e.target.name]= e.target.value)
    }
  }

  let sendData = new FormData();
  sendData.append('title', titlePhotos);
  sendData.append('picture', img);
  sendData.append('category', categoryPhotos);
  sendData.append('create_by', localStorage.id)
  sendData.append('photo_status', "Foto Baru");
  //for reload page javascript  window.location.reload();
  
  function editData(id){
    let editData = new FormData();
    editData.append('title', titlePhotos);
    editData.append('picture', img);
    editData.append('category', categoryPhotos);
    editData.append('caption', captionPhotos);
    editData.append('create_by', localStorage.id)
    
    if (userAccess !== "kontributor"){
      editData.append('photo_status', "Telah diedit");
    } else{
      editData.append('photo_status', "Foto Baru");
    }

    axios.put(url+"/private/photos/"+id,editData,{headers}).then(()=> {
      setOpenModalEdit(false);
      getData(); 
    })
  }

  function editDataForPublish(id){
    let EditPublish = new FormData();
    EditPublish.append('title', titlePhotos);
    EditPublish.append('picture', img);
    EditPublish.append('category', categoryPhotos);
    EditPublish.append('caption', captionPhotos);
    EditPublish.append('create_by', localStorage.id)
    EditPublish.append('photo_status', "Publish");

    axios.put(url+"/private/photos/"+id,EditPublish,{headers}).then(()=> {
      setOpenModalEdit(false);
      getData();
    })
  }

  function deleteData(id) {
    axios.delete(url+"/private/photos/"+id,{headers}).then(res=> {
      setOpenModalEdit(false);
      getData();
    })
  }

  // var user_access = localStorage.getItem('user_access')
  // console.log('User photo access ', user_access)
  // console.log('Category Photo ', dataCategory)
  // console.log('Data Photo ', data)

  return (
    <>
      {/* button="Tambah Kategori" click={()=>props.history.push("/app/gallery/categories-photos")}  */}
      <PageTitle title="Galeri Foto" />
      <Grid container spacing={4}>
        <Grid item lg={12}>
          {
            dataCategory.map((category,index)=>(
          <div>
          <Paper key={index} style={{marginTop:40,marginBottom:20,paddingTop:10,paddingBottom:20}}>
            <Grid container>
              <Typography className={classes.txtTitleCategory}>{category.name}</Typography>
              <Button 
                classes={{ root: classes.button }}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.btnAdd}
                component={NavLink} 
                to={"/app/gallery/photos/add-photos/"+category._id}
              >
                <Typography style={{textTransform:'none'}}>Tambah Foto</Typography>
              </Button>
            </Grid>
          </Paper>
          <Grid container spacing={2}>
              {
                data.map((item,index)=>{
                if(item.category == category._id){
                return(
                  <Grid item key={index} lg={2}>
                    <div style={{width:'100%', lineHeight:1, textAlign:'center', 
                        backgroundColor:item.photo_status === "Publish" ? 'blue': 
                        item.photo_status === "Telah diedit" ? 'green':'grey' , 
                        padding:3, color:'white'}}>
                      {item.photo_status}
                    </div>
                    <div className={classes.contentPhotos} onClick={()=>handleClickOpenEdit(item._id,item.title, item.caption, item.category,item.picture)} style={{cursor:'pointer', padding:5}}>
                      <img alt="img" src={url+"/repo/"+item.picture} className={classes.sizePicture}/>
                      <Typography style={{fontSize:12, textAlign:'center', fontWeight:'bold'}}>{item.title.length > 60 ? item.title.slice(0, 55) + "..." : item.title}</Typography>
                      <Divider/>
                      <Typography style={{fontSize:8, textAlign:'center'}}>{item.caption.length > 50 ? item.caption.slice(0, 50) + "..." : item.caption}</Typography>
                    </div>
                  </Grid>
                )
                }}
                )
              }
          </Grid>
          </div>
            ))
          }
        </Grid>
      </Grid>

      {/* <==========================================================================================================================================> */}

      {/* modal edit */}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Foto"}
        content={
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Judul"
                className={classes.formContainer}
                margin="normal"
                value={titlePhotos}
                onChange={e => handleInput("title",e)}
                name="name"
              />
            </Grid> 
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Keterangan"
                className={classes.formContainer}
                margin="normal"
                value={captionPhotos}
                onChange={e => handleInput("caption",e)}
                name="name"
              />
            </Grid> 
            <Grid item lg={12} md={12} sm={12} xs={12}> 
            Upload Image : 
              <input
                accept="image/*"
                className={classes.input}
                ref={imgRef}
                multiple
                type="file"
                onChange={e =>handleImage(e)}
              />
              <Button className={classes.btnUpload} onClick={handleInputFile}>
                <Typography style={{color:'#ffffff'}}>Upload</Typography>
              </Button>
            
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img
                alt="img"
                src={imgPreview}
                height="100px"
                style={{marginTop:20}}
              />
              <br/>
              {img.name}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop:20,height:40}}>
              <Button onClick={()=>editData(idPhotos)} style={{border:'2px solid #6f9ae8',marginRight:10}}>
                <Typography style={{textTransform:'none'}}>Simpan Konten</Typography>
              </Button>
              {
                localStorage.user_access === "admin" || localStorage.user_access === "redaktor"  ? 
                <Button onClick={()=>editDataForPublish(idPhotos)} style={{border:'2px solid #78f056',marginRight:10}}>
                  <Typography style={{textTransform:'none'}}>Publish</Typography>
                </Button> : null
              }
              
              <Button onClick={handleClose} style={{border:'2px solid gray',marginRight:10}}>
                <Typography style={{textTransform:'none'}}>Batalkan</Typography>
              </Button>
              {/* {userAccess === "kontributor" ? null :
              (
              )
              } */}
              <Button onClick={()=>deleteData(idPhotos)} style={{border:'2px solid #de1200',marginRight:10}}>
                <Typography style={{textTransform:'none'}}>Hapus</Typography>
              </Button>
              
            </Grid>
          </Grid>
        }
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>{titlePhotos}</Typography>
        }
        cancel={handleClose}
        confirm={()=>deleteData(idPhotos)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />

      <Dialog
        open={openAlert}
        close={()=>setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={
          <Typography>{messageError}</Typography>
        }
        cancel={()=>setopenAlert(false)}
        confirm={()=>setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
    </>
  );
}
