import React, { useState,useEffect } from "react";
import {
  Grid, 
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  InputLabel,
  Avatar,
  Select,
  InputAdornment,
  MenuItem,
  FormControl,
  Input,
  TableContainer,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';

import organzationData from './data'
import Api from "../../Services/Api";
import { NavLink } from "react-router-dom";

import TablePagination from '@material-ui/core/TablePagination';

import useStyles from "./styles";

import PageTitle from '../Components/PageTitle/PageTitle'
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import { Delete, Edit } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";


const Organization = (props) => {

  var classes = useStyles();
  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const [head, setHead] = useState("")
  const [departement, setDepartement] = useState("")
  const [parent, setParent] = useState("")

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("")
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [action, setaction] = useState("")

  const handleClickOpen = () => {
    props.history.push('/app/organization/form/0')
  }

  const getData = async () => { 
    setLoading(true)
    await Api.getOrganization()
      .then((res)=>{
        const data = res.data
        console.log("Data = ", data)
        // if (data.length > 0){
          setLoading(false)
        // }
        setRows(data)
        setData(data)
      })

  }

  const handleClose = () => {
    setOpen(false);
    setOpenModalDelete(false);
  };

  const handleDialogDelete = (id) => { 
    setOpenModalDelete(true)
    setId(id)
   }

  
  const handleOpenEditData = (idEdit) => { 
    props.history.push('/app/organization/edit/' + idEdit)
   }

  const deleteData = () => { 
    Api.deleteOrganization(id)
      .then(()=> {
        setOpenModalDelete(false)
        getData()
      })
   }
  
  /** Table Pagination */
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("")

  // const handleSearch = (event) => {
  // var searchQuery = event.target.value.toString().toLowerCase();
  // const result = data.filter((e)=>{
  //   return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
  // })
  // setRows(result)    
  // setSearch(searchQuery)
  // }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setRowsPerPage(+rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
    getData()
  }, [])

  // console.log("Data rows ", rows)

  return (
    <>
    <PageTitle title="Organisasi" button="Tambah Organisasi" click={handleClickOpen}
      filter={
        <TextField
          variant="outlined"
          // onChange={handleSearch}
          value={search}
          className={classes.searchField}
          placeholder="Cari.."
          InputProps={{
            startAdornment: (
                <InputAdornment position='start'>
                    <SearchIcon />
                </InputAdornment>
            ),
            classes : {input : classes.textSearch}
          }}
        />
      }
    />

    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ width: '100%'}}>
          {loading ?
          <center style={{padding:50}}>
            <CircularProgress />
            <Typography>Sedang memuat data...</Typography>
          </center>
        :
        <Box sx={{ width: '100%'}}>
          <List>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index)=> {
                return (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={item.name}
                      secondary={item.level}
                    />
                    <ListItemSecondaryAction>
                      <IconButton component={NavLink} to={"/app/organization/edit/" + item._id}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDialogDelete(item._id)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
          </List>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        }
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
        <Pagination 
            sx={{ my:20 }}
            variant="outlined" 
            shape="rounded"
            onChange={handleChangePage}
            count={Math.ceil(rows.length / rowsPerPage)}
            page={page}
          />
        </Grid>
      </Grid> */}
    </Grid>
    

    {/* Modal Edit */}

    {/* Modal Delete */}
    <Dialog 
      open={openModalDelete}
      title="Hapus Organisasi?"
      valueConfirm="Ya"
      valueCancel="Batalkan"
      cancel={()=> setOpenModalDelete(false)}
      confirm={() => deleteData()}
    />
    </>
  )
}

export default Organization
