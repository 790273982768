import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";

import tinymce from "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/code";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoImg from "../../Assets/Images/no-image.png";
// import draftToHtml from 'draftjs-to-html';
import Dialog from "../Components/Dialog/Dialog";
import Api from "../../Services/Api";
import StaticVar from "../../Config/StaticVar";

export default function EditorContent(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const id = props.match.params._id;

  let token = localStorage.getItem("token");
  const userAccess = localStorage.getItem("user_access")

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  let [data, setData] = useState([]);
  const [dataOrganization, setDataOrganization] = useState([])
  let [title, settitle] = useState('');
  let [titleEN, settitleEN] = useState('');
  let [status, setStatus] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  var [section, setsection] = useState("");
  const [category, setCategory] = useState("")
  var [hashtag, sethashtag] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [editorState, seteditorState] = useState("");
  var [editorENState, seteditorENState] = useState("");
  var [editorValue, seteditorValue] = useState("");
  var [editorENValue, seteditorENValue] = useState("");
  var [caption, setcaption] = useState("");
  const [source, setSource] = useState("")

  const getDataCategory = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/news_categories/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      console.log("Section in data category ", response[0].name)
      if (response.length > 0){
        setsection(response[0]._id)
        setCategory(response[0].name)
      }
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/news_categories", { headers });
      let response = await res.data;
      // setsection(response[0].name)
      setDataCategory(response);
    }
  };

  const getDataOrganization = () => { 
    Api.getOrganization()
      .then((res)=>{
        const data = res.data
        const dataSection = data.filter((val)=> val.level === "Seksi")
        setDataOrganization(dataSection)
      })
   }

  async function getData() {
    let res = await axios.get(url + "/private/news/" + id, { headers });
    let response = await res.data[0];
    console.log('response category ', response.category)
    setsection(response.category);
    setImgPreview(url + "/repo/" + response.picture);
    settitle(response.title);
    settitleEN(response.titleEN);
    setcaption(response.caption)
    setStatus(response.news_status);
    sethashtag(response.hashtag.join(' '));
    setSource(response.source)
    // setsection(response.section);

    const datasample = response.content;
    console.log('content', datasample)
    seteditorState(datasample);
    seteditorValue(datasample)
    const datasampleEN = response.contentEN;
    console.log('contentEN', datasampleEN)
    seteditorENState(datasampleEN);
    seteditorENValue(datasampleEN)
  }

  useEffect(() => {
    getDataCategory();
    getData();
    // getDataOrganization()
    // return () => {
    //   getDataCategory();
    //   getData();
    // };
  }, []);

  function handleEditorChange(value,editor) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(value) {
    seteditorENState(value);
    console.log("Content was updated:", editorState);
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  // const refEditor = useRef(null)
  // const refEditor = useRef(null)
  console.log("Section in editor now ", section)

  return (
    <>
      <PageTitle title="Konten Berita" status={"Status : " + status} />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={5} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Judul Berita"
                value={title}
                name="title"
                onChange={event => settitle(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginBottom: 2 }}>
          <Grid item xs={6}>
              {/* <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="section-field">Seksi</InputLabel>
                <Input 
                  id='section-field' 
                  value={section} 
                  // defaultValue={section}
                  onChange={ event => {setsection(event.target.value)}}
                  inputProps={{
                    "aria-readonly": true,
                  }}
                  />
              </FormControl> */}
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="section-field">Kategori</InputLabel>
                <Select
                  id="section-field"
                  label="Kategori"
                  value={section}
                  onChange={e => setsection(e.target.value)}
                >
                  <MenuItem value="">Pilih Kategori..</MenuItem>
                  {dataCategory.map((item, index)=>(
                    <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* { userAccess === "admin" ?
              (
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="section-field">Kategori</InputLabel>
                  <Select
                    id="section-field"
                    label="Kategori"
                    value={section}
                    onChange={e => setsection(e.target.value)}
                  >
                    <MenuItem value="">Pilih Kategori..</MenuItem>
                    {dataCategory.map((item, index)=>(
                      <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) 
              :
              (
                <TextField
                  className={classes.formContainer}
                  margin="normal"
                  label="Kategori"
                  value={category}
                  defaultValue={category}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="section"
                  onChange={event => setCategory(event.target.value)}
                />
              )
              } */}
            </Grid>
            <Grid item xs={6}>
              {/* <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="hashtag-field">Hashtag</InputLabel>
                <Input 
                  id='hashtag-field' 
                  value={hashtag} 
                  onChange={ event => {sethashtag(event.target.value)}}
                  />
              </FormControl> */}
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Hashtag"
                value={hashtag}
                name="hashtag"
                onChange={event => sethashtag(event.target.value)} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  className={classes.formContainer}
                  margin="normal"
                  label="Sumber"
                  value={source}
                  name="source"
                  onChange={event => setSource(event.target.value)} />
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 15, marginBottom: 15 }}>
            <InputLabel htmlFor="contributor-helper">Foto Berita</InputLabel>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              // ref={imgRef}
              type="file"
              onChange={e => handleImage(e)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.btnUpload}
                // onClick={handleInputFile}
              >
                <Typography style={{ color: "#ffffff" }}>
                  File Upload
                </Typography>
              </Button>
            </label>
            <br />
            <img src={imgPreview} width="100%" height={200} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <TextField
                className={classes.formContainer}
                margin="normal"
                label="Caption Foto"
                value={caption}
                name="Caption Foto"
                onChange={event => setcaption(event.target.value)}
              />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>Isi Berita</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                  apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                  value={editorState}
                  init={{
                      file_picker_callback: function (
                          callback,
                          value,
                          meta
                      ) {
                          if (meta.filetype == "image") {
                              var input =
                                  document.getElementById(
                                      "file-editor"
                                  )
                              input.click()
                              input.onchange = function () {
                                  var reader = new FileReader()
                                  var file = input.files[0]
                                  var options = {
                                      maxSizeMB: 0.3,
                                      maxWidthOrHeight: 3000,
                                      useWebWorker: true
                                  }
                                  imageCompression(
                                      file,
                                      options
                                  ).then(function (
                                      compressedFile
                                  ) {
                                      reader.onload = function (
                                          e
                                      ) {
                                          callback(
                                              e.target.result,
                                              {
                                                  alt: compressedFile.name
                                              }
                                          )
                                      }
                                      reader.readAsDataURL(
                                          compressedFile
                                      )
                                  })
                              }
                          }
                      },
                      paste_data_images: true,
                      mobile: true,
                      images_upload_handler: function (
                          blobInfo,
                          success,
                          failure
                      ) {
                          success(
                              "data:" +
                                  blobInfo.blob().type +
                                  ";base64," +
                                  blobInfo.base64()
                          )
                      },
                      branding: false,
                      height: 500,
                      menubar: true,
                      plugins: [
                          "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                          "searchreplace wordcount visualblocks visualchars code fullscreen",
                          "insertdatetime media table paste code help fullpage",
                          "directionality",
                          "image"
                      ],
                      toolbar:
                          "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help "
                  }}
                  // onDragDrop={true}
                  // onChange={(e) => handleEditorChange(e)}
                  onEditorChange={(newValue, editor) =>
                      handleEditorChange(newValue, editor)
                  }
                />
              </Box>
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <Typography>News Content</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor-en"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                   apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                   value={editorENState}
                   init={{
                       file_picker_callback: function (
                           callback,
                           value,
                           meta
                       ) {
                           if (meta.filetype == "image") {
                               var input =
                                   document.getElementById(
                                       "file-editor"
                                   )
                               input.click()
                               input.onchange = function () {
                                   var reader = new FileReader()
                                   var file = input.files[0]
                                   var options = {
                                       maxSizeMB: 0.3,
                                       maxWidthOrHeight: 3000,
                                       useWebWorker: true
                                   }
                                   imageCompression(
                                       file,
                                       options
                                   ).then(function (
                                       compressedFile
                                   ) {
                                       reader.onload = function (
                                           e
                                       ) {
                                           callback(
                                               e.target.result,
                                               {
                                                   alt: compressedFile.name
                                               }
                                           )
                                       }
                                       reader.readAsDataURL(
                                           compressedFile
                                       )
                                   })
                               }
                           }
                       },
                       paste_data_images: true,
                       mobile: true,
                       images_upload_handler: function (
                           blobInfo,
                           success,
                           failure
                       ) {
                           success(
                               "data:" +
                                   blobInfo.blob().type +
                                   ";base64," +
                                   blobInfo.base64()
                           )
                       },
                       branding: false,
                       height: 500,
                       menubar: true,
                       plugins: [
                           "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                           "searchreplace wordcount visualblocks visualchars code fullscreen",
                           "insertdatetime media table paste code help fullpage",
                           "directionality",
                           "image"
                       ],
                       toolbar:
                           "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help "
                   }}
                   // onDragDrop={true}
                   // onChange={(e) => handleEditorChange(e)}
                   onEditorChange={(newValue, editor) =>
                    handleEditorENChange(newValue, editor)
                   }
                />
              </Box>
            </Grid> */}
          
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{float:'right'}}>
          <Button
            style={{ width: 120, border: "2px solid gray", color: "black",marginRight:10 }}
            onClick={() => {
              props.history.push("/app/news/content");
            }}
          >
            Batal
          </Button>
          <Button
            style={{
              width: 150,
              border:'2px solid #6f9ae8',
              marginRight: 10,
            }}
            onClick={() => {
              let sendData = new FormData();
              sendData.append("title", title);
              sendData.append("titleEN", titleEN);
              sendData.append("category", section);
              sendData.append("caption", caption);
              sendData.append("hashtag", hashtag);
              sendData.append("picture", img);
              sendData.append("source", source);
              sendData.append("content", editorState);
              sendData.append("contentEN", editorENState);
              sendData.append("news_status", status);
              axios
                .put(url + "/private/news/" + id, sendData, { headers })
                .then(() => {
                  props.history.push("/app/news/content");
                });
            }}
          >
            Simpan Konten
          </Button>
          {localStorage.getItem("user_access") === "kontributor" ? null : (
            <Button
              style={{
                width: 150,
                border:'2px solid #78f056',
                marginRight: 10,
              }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("title", title);
                sendData.append("titleEN", titleEN);
                sendData.append("category", section);
                sendData.append("caption", caption);
                sendData.append("hashtag", hashtag);
                sendData.append("source", source);
                sendData.append("picture", img);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                // sendData.append("edit_by", localStorage.getItem("id"));
                sendData.append("edit_by", localStorage.id);  
                sendData.append("news_status", "Telah diedit");
                axios
                  .put(url + "/private/news/" + id, sendData, { headers })
                  .then(() => {
                    props.history.push("/app/news/content");
                  });
              }}
            >
              Submit Editor
            </Button>
          )}
          {
          localStorage.getItem("user_access") === "kontributor" ||
          localStorage.getItem("user_access") === "editor" ? null : 
          localStorage.getItem("user_access") === "admin" || localStorage.getItem("user_access") === "publisher" || localStorage.getItem("user_access") === "redaktor" ?
          (
            <Button
              style={{
                width: 150,
                border:'2px solid #bf272b',
                color:'#bf272b'
              }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("approve_by", localStorage.id);
                sendData.append("title", title);
                sendData.append("titleEN", titleEN);
                sendData.append("category", section);
                sendData.append("caption", caption);
                sendData.append("source", source);
                sendData.append("hashtag", hashtag);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("news_status", "Publish");
                axios
                  .put(url + "/private/news/" + id, sendData, { headers })
                  .then(() => {
                    props.history.push("/app/news/content");
                  });
              }}
            >
              Publish
            </Button>
          ): null}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
