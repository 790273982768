import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  CircularProgress,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import StaticVar from "../../Config/StaticVar";
import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import TablePagination from "@material-ui/core/TablePagination";
import useStyles from "./styles";
import Dialog from "../Components/Dialog/Dialog";
import moment from "moment";

const Funds = (props) => {
  const classes = useStyles();
  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idReport, setIdReport] = useState("")
  const [titleReport, setTitleReport] = useState("")

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [creator, setCreator] = useState("0");
  const [users, setusers] = useState([]);
  var [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  function deleteData(id) {
    axios.delete(url + "/private/report/" + id, { headers }).then(() => {
      setOpenModalDelete(false);
      getData();
    })
  }

  const filterData = (result) => {
    const date = Date.parse(result.created.create_date);
    const inputStart = Date.parse(startDate);
    const inputEnd = Date.parse(endDate);

    if (creator === "1") {
      return date >= inputStart && date <= inputEnd;
    } else {
      return (
        result.created.create_by === creator &&
        date >= inputStart &&
        date <= inputEnd
      );
    }
  };

  const getDataFilter = () => {
    if (creator === "0" || creator === "1") getData();
    else {
      const dataFilter = data.filter(filterData);
      setRows(dataFilter);
    }
  };

  function handleClickOpenDelete(id, title) {
    setIdReport(id);
    setTitleReport(title);
    setOpenModalDelete(true);
  }

  function getData() {
    setLoading(true);
    axios
      .get(url + "/private/report/funds", { headers })
      .then((res) => {
        let response = res.data;
        console.log("Res ", response);
        setRows(response);
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e) => {
      return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
    });
    setRows(result);
    setSearch(searchQuery);
  };

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleClickOpen = () => {
    props.history.push("/app/report/funds/form");
  };

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData();
    axios
      .get(url + "/private/users", { headers })
      .then((res) => {
        setusers(res.data);
      })
      .catch((err) => console.log(err));
    // return () => {
    //   getData();
    // }
  }, []);

  return (
    <>
      <PageTitle
        title="Laporan Keuangan"
        button="Tambah Laporan"
        click={handleClickOpen}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.textSearch },
            }}
          />
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Box>
            Cari Berdasarkan :
            {/* <Select style={{marginRight:5}} value={filterdate} onChange={(e)=>setfilterdate(e.target.value)}>
            <MenuItem>Pilih Tanggal</MenuItem>
          </Select> */}
            Dari Tgl
            <TextField
              type="Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            sampai
            <TextField
              type="Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ marginRight: 5 }}
            />
            <Select
              style={{ marginRight: 5 }}
              disable={localStorage.user_access === "admin" ? "false" : "true"}
              value={creator}
              onChange={(e) => setCreator(e.target.value)}
            >
              <MenuItem value="0">Pilih Kontributor</MenuItem>
              <MenuItem value="1">Semua</MenuItem>
              {users.map((item) => {
                return (
                  <MenuItem key={item} value={item._id}>
                    {item?.email}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                getDataFilter();
              }}
            >
              Cari
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {loading ? (
              <center style={{ padding: 50 }}>
                <CircularProgress />
                <Typography>Sedang memuat data...</Typography>
              </center>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No</TableCell>
                        <TableCell align="center">Judul</TableCell>
                        <TableCell align="center">File</TableCell>
                        <TableCell align="center">Komponen</TableCell>
                        <TableCell align="center">Aktivitas</TableCell>
                        <TableCell align="center">Bulan</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(rows, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.title}</TableCell>
                            <TableCell align="center">
                              <a href={url + "/repo/" + item.file_document} target="_blank">
                                <Button
                                  variant="outlined"
                                  style={{ border: '2px solid #bf0d00', textTransform: 'none', fontSize: 10 }}
                                >{item.file_document}</Button>
                              </a>
                            </TableCell>
                            <TableCell align="center">{item.component}</TableCell>
                            <TableCell align="center">{item.activity}</TableCell>
                            <TableCell align="center">{item.month}</TableCell>
                            <TableCell align="center">
                              <Button
                                className={classes.btnAction}
                                component={NavLink}
                                to={'/app/report/funds/form/' + item._id}
                              >Edit</Button>
                              {localStorage.getItem("user_access") ===
                                "editor" ||
                                localStorage.getItem("user_access") ===
                                "kontributor" ? null : (
                                <Button
                                  className={classes.btnAction} onClick={() => handleClickOpenDelete(item._id, item.title)}
                                >Hapus</Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Typography>Laporan dengan Judul : {titleReport}</Typography>
        }
        cancel={handleClose}
        confirm={() => deleteData(idReport)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />
    </>
  )
};

export default Funds;
